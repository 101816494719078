import axios from ".";

export const getOrder = async (params) =>
  axios.get("/admin/orders", {
    params: { perPage: 50, page: 1, orderBy: "desc", ...params },
  });
export const getOrderById = async (id) => axios.get(`/admin/orders/${id}`);

export const createOrder = async (payload) =>
  axios.post("/admin/orders", payload);

export const updateOrder = async (id, payload) =>
  axios.patch(`/admin/orders/${id}`, payload);

export const updateProcess = async (id, payload) =>
  axios.patch(`/admin/orders/${id}/progress-order`, payload);

export const updateHighlight = async (payload) =>
  axios.patch('/admin/orders/highlight', payload);

export const getOrderMember = async (params) =>
  axios.get("/orders", {
    params: { perPage: 50, page: 1, orderBy: "desc", ...params },
  });

export const getOrderByIdMember = async (id) => axios.get(`/orders/${id}`);

export const createOrderMember = async (payload) =>
  axios.post("/orders", payload);

export const updateOrderMember = async (id, payload) =>
  axios.patch(`/orders/${id}`, payload);

export const updateProcessMember = async (id, payload) =>
  axios.patch(`/orders/${id}/progress-order`, payload);

export const confirmOrder = async (id, payload) =>
  axios.patch(`/orders/${id}/confirm-order`, payload);


export const updateProcessOrderAssignee = async (id, payload) =>
  axios.patch(`/orders/${id}/progress-order-assignee`, payload);

export const deleteOrder = async (id) => axios.delete(`/admin/orders/${id}`);

const OrderServices = {
  getOrder,
  createOrder,
  updateOrder,
  getOrderById,
  updateProcess,
  getOrderMember,
  getOrderByIdMember,
  createOrderMember,
  updateOrderMember,
  updateProcessMember,
  deleteOrder,
  confirmOrder,
  updateProcessOrderAssignee
}

export default OrderServices;
