import React from "react";
import { useHref } from "react-router-dom";

const NotFound = () => {
  const route = useHref();
  return (
    <div className="flex justify-center items-center w-full h-[70vh] text-3xl text-gray-500 font-bold">
      {route === "/login"
        ? "Chương trình đang cập nhật đơn hàng"
        : "Page Not Found!"}
    </div>
  );
};

export default NotFound;
