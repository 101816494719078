import { AttachFileOutlined, Close } from "@mui/icons-material";
import { Autocomplete, Box, Grid, TextField, Typography } from "@mui/material";
import OrderServices from "api/order";
import { ReactComponent as IconDownload } from "assets/Group.svg";
import ButtonCustom from "components/button";
import moment from "moment";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { open } from "store/notificationSlice";
import UploadFile from "./UploadFile";

const ModalEdit = ({ data, handleClose, fetchOrderDetail }) => {
  const [form, setForm] = useState(data);
  const { id } = useParams();
  const dispatch = useDispatch();

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 650,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: 4,
    p: 4,
  };

  const PROCESS = {
    true: "Đã hoàn thành",
    false: "Chưa hoàn thành",
  };

  const CUSTOMER_CONFIRM = [
    { label: "KH đã duyệt", value: true },
    { label: "KH chưa duyệt", value: null },
  ];

  const handleSubmit = async () => {
    try {
      const payload = {
        ...form,
      };
      await OrderServices.updateProcess(id, payload);

      dispatch(
        open({
          isNoti: true,
          message: "Cập nhật thành công!",
        })
      );
      handleClose();
      fetchOrderDetail();
    } catch (error) {
      console.log(error);
      dispatch(
        open({
          isNoti: true,
          message: error.response?.data?.message,
        })
      );
    }
  };

  const handleConfirm = async () => {
    try {
      const payload = {
        ...form,
        customerConfirm: true,
      };
      await OrderServices.updateProcess(id, payload);

      dispatch(
        open({
          isNoti: true,
          message: "Cập nhật thành công!",
        })
      );
      handleClose();
      fetchOrderDetail();
    } catch (error) {
      console.log(error);
      dispatch(
        open({
          isNoti: true,
          message: error.response?.data?.message,
        })
      );
    }
  };

  return (
    <Box sx={style} className="!w-[95%] md:!w-[650px]">
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography fontWeight={700} variant="h5">
            Chi tiết
          </Typography>
        </Grid>
        <Grid item xs={6} className="w-full flex justify-end items-center">
          <span
            className="z-50 border rounded-full p-2 cursor-pointer"
            onClick={handleClose}
          >
            <Close />
          </span>
        </Grid>
      </Grid>

      <Grid container spacing={5} className="!mt-[-8px]">
        <Grid item xs={6}>
          <TextField
            name="name"
            value={form?.user?.name}
            label="Tiến độ"
            variant="standard"
            className="w-full"
            focused={false}
          />
        </Grid>
        <Grid item xs={6}>
          {form?.type === "designer" ? (
            <Autocomplete
              name="customerConfirm"
              inputValue={
                CUSTOMER_CONFIRM?.filter(
                  (item) => item.value === form?.customerConfirm
                )[0]?.label || ""
              }
              onChange={(_, newValue) => {
                setForm({ ...form, customerConfirm: newValue?.value });
              }}
              getOptionLabel={(option) => option?.label}
              options={CUSTOMER_CONFIRM}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Trạng thái"
                  name="customerConfirm"
                  variant="standard"
                />
              )}
            />
          ) : (
            <TextField
              name="tick"
              value={PROCESS[form?.tick]}
              label="Trạng thái"
              variant="standard"
              className="w-full"
              focused={false}
            />
          )}
        </Grid>
      </Grid>

      {form?.type === "machineBendingWoker" ? (
        <Grid container spacing={5} className="!mt-[2px]">
          <Grid item xs={6}>
            <TextField
              value={`${moment(form?.updatedAt).format(
                "DD/MM/YYYY"
              )} - ${moment(form?.updatedAt).format("LT")}`}
              label="Ngày chỉnh sửa gần nhất"
              variant="standard"
              className="w-full !pt-2"
              focused={false}
            />
          </Grid>
          {form?.customerConfirm ? (
            <Grid item xs={6}>
              <Typography className="text-gray-500 !text-[13px]">
                Xác nhận để bắt đầu giai đoạn 6
              </Typography>
              <TextField
                value={"Đã xác nhận"}
                variant="standard"
                className="w-full !mt-1 !text-blue-500"
                focused={false}
              />
            </Grid>
          ) : (
            <Grid item xs={6}>
              <Typography className="text-gray-500 !text-[13px]">
                Xác nhận để bắt đầu giai đoạn 6
              </Typography>
              <ButtonCustom
                size="small"
                classes={"mt-2"}
                onClick={handleConfirm}
              >
                Xác nhận
              </ButtonCustom>
            </Grid>
          )}
        </Grid>
      ) : (
        <Grid container spacing={5} className="!mt-[2px]">
          <Grid item xs={12}>
            <TextField
              value={`${moment(form?.updatedAt).format(
                "DD/MM/YYYY"
              )} - ${moment(form?.updatedAt).format("LT")}`}
              label="Ngày chỉnh sửa gần nhất"
              variant="standard"
              className="w-full"
              focused={false}
            />
          </Grid>
        </Grid>
      )}

      <Grid container spacing={5} className="!mt-[2px]">
        <Grid item xs={12}>
          <TextField
            name="description"
            value={form?.description}
            label="Ghi chú"
            className="w-full"
            focused={false}
            onChange={(e) => setForm({ ...form, description: e.target.value })}
            multiline
            inputProps={{
              style: {
                height: "80px",
              },
            }}
          />
        </Grid>
      </Grid>

      <Grid container spacing={5} className="pl-2 pt-2">
        <Grid item xs={12}>
          <div className="flex flex-col gap-2 mt-4 -ml-2">
            {form?.files?.map((item) => (
              <div
                key={item}
                className="flex gap-1 cursor-pointer border rounded-xl p-1"
              >
                <span>
                  <AttachFileOutlined fontSize="small" />
                </span>
                <a
                  href={item}
                  target="_blank"
                  className="text-gray-500 w-full truncate"
                  rel="noreferrer"
                >
                  {item.split("/").splice(6).join("")}
                </a>
                <span
                  onClick={() =>
                    setForm({
                      ...form,
                      files: form?.files?.filter((i) => i !== item),
                    })
                  }
                >
                  <Close size="small" />
                </span>
              </div>
            ))}
          </div>
        </Grid>
      </Grid>

      <Grid container mt={2}>
        <Grid
          item
          xs={12}
          className="relative w-full h-[140px] border-gradient-primary border-gradient-rounded"
        >
          <UploadFile
            value={form?.files}
            onChange={(value) =>
              setForm((prev) => ({
                ...prev,
                files: [...(form.files || []), ...value],
              }))
            }
            imageAspect="1/1"
            folder="files"
            classes={
              "absolute top-0 -z-50 w-full h-full cursor-pointer duration-200"
            }
            helperText={
              <div className="w-full flex flex-col justify-center items-center h-full">
                <span>
                  <IconDownload />
                </span>
                <span className="text-xl">Tải hình ảnh, files</span>
              </div>
            }
          />
        </Grid>
      </Grid>

      <Grid container mt={4}>
        <Grid item xs={12} className="flex justify-end">
          <ButtonCustom onClick={handleSubmit}>Lưu thay đổi</ButtonCustom>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ModalEdit;
