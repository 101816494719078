import axios from ".";

export const getCustomer = async (params) =>
  axios.get("/admin/customers", {
    params: { perPage: 1000, page: 1, orderBy: "asc", ...params },
  });
export const getCustomerById = async (id) =>
  axios.get(`/admin/customers/${id}`);

export const createCustomer = async (payload) =>
  axios.post("/admin/customers", payload);

export const updateCustomer = async (id, payload) =>
  axios.patch(`/admin/customers/${id}`, payload);

export const getCustomerForCreator = async (params) =>
  axios.get("/customers", {
    params: { perPage: 1000, page: 1, orderBy: "asc", ...params },
  });

export const getCustomerByIdForCreator = async (id) =>
  axios.get(`/customers/${id}`);

export const deleteCustomer = async (id) =>
  axios.delete(`/admin/customers/${id}`);

const CustomerServices = {
  getCustomer,
  createCustomer,
  updateCustomer,
  getCustomerById,
  getCustomerForCreator,
  getCustomerByIdForCreator,
  deleteCustomer,
};

export default CustomerServices;
