export const USER_INFO = "userInfo";
export const BASE_URL = process.env.REACT_APP_PROXY;

export const Roles = [
  "admin",
  "creator",
  "designer",
  "lazerWorker",
  "machineBendingWoker",
  "handBendingWorker",
  "qc",
  "shipper",
];

export const RolesNotAdmin = [
  "creator",
  "designer",
  "lazerWorker",
  "machineBendingWoker",
  "handBendingWorker",
  "qc",
  "shipper",
];

export const VietnameseRoles = {
  admin: "Quản lý",
  creator: "Người tạo đơn",
  designer: "Thiết kế",
  lazerWorker: "Thợ lazer",
  machineBendingWoker: "Thợ uốn máy",
  handBendingWorker: "Thợ thủ công",
  qc: "QC",
  shipper: "Giao hàng",
};

export const PROCESS = {
  new: "Đơn hàng mới",
  pending: "Đang tiến hành",
  completed: "Đã hoàn tất",
  cancel: "Đã huỷ",
};
export const PROCESS_COLOR = {
  new: "black",
  pending: "orange",
  completed: "green",
  cancel: "red",
};

export const SHAPE = {
  straight: 'Khuôn Thẳng',
  circle: 'Khuôn Tròn',
  straight3d: 'Khuôn thẳng có kèm khuôn 3D - 2D',
  straightGorac: 'Khuôn thẳng có kèm khuôn gở rác',
  cnc: 'Khuôn CNC',
  flexo: 'Khuôn Flexo',
  decal: 'Khuôn Decal tem nhãn',
  threeD2dZinc: 'Khuôn 3D - 2D kẽm',
  threeD2dCopper: 'Khuôn 3D - 2D đồng',
  threeD2dAluminum: 'Khuôn 3D - 2D nhôm',
  shape: "Khuôn định hình"
}

export const TYPE_SHAPE = {
  new: 'Khuôn làm mới',
  repair: 'Khuôn sửa'
}

export const CONTACT_FORM = {
  email: 'Email',
  skype: 'Skype',
  zalo: 'Zalo',
  sample: 'Lấy mẫu',
}

export const LOCATION = {
  south: 'Miền Nam',
  north: 'Miền Bắc',
  all: 'Tất cả'
}