import {
  Box,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import ButtonCustom from "components/button";
import { LOCATION } from "constant";

const Create = ({
  form,
  handleSubmitForm,
  handleChangeInput,
  handleClose,
  listRole,
}) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: 4,
    p: 4,
  };

  return (
    <Box p={3} sx={style} className="boxShadow w-[96%] md:w-[450px]">
      <Typography variant="h5" mb={3} fontWeight={700}>
        Thêm nhân sự
      </Typography>
      <Divider />
      <Grid container spacing={2} my={1}>
        <Grid item xs={12}>
          <TextField
            id="outlined-basic"
            label="Họ và tên"
            variant="outlined"
            name="name"
            className="w-full"
            value={form.name}
            onChange={handleChangeInput}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel id="role">Bộ phận</InputLabel>
            <Select
              labelId="role"
              name="role"
              id="role"
              value={form.role}
              label="role"
              className="w-full"
              onChange={handleChangeInput}
            >
              {listRole.map((e) => (
                <MenuItem key={e.value} value={e.value}>
                  {e.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel id="location">Khu vực</InputLabel>
            <Select
              labelId="location"
              name="location"
              id="location"
              value={form.location}
              label="location"
              className="w-full"
              onChange={handleChangeInput}
            >
              {Object.keys(LOCATION).map((k) => (
                <MenuItem key={k} value={k}>
                  {LOCATION[k]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Tài khoản"
            variant="outlined"
            name="username"
            className="w-full"
            value={form.username}
            onChange={handleChangeInput}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="Mật khẩu"
            variant="outlined"
            name="password"
            type="password"
            className="w-full"
            defaultValue={"12345678"}
            value={form.password}
            onChange={handleChangeInput}
          />
        </Grid>

        
      </Grid>

      <Grid container className="w-full flex gap-2 justify-between mt-4">
        <ButtonCustom
          classes="w-[48%] py-3 !text-black"
          variant="none"
          onClick={handleClose}
        >
          Huỷ
        </ButtonCustom>
        <ButtonCustom
          classes="w-[48%] py-3"
          variant="primary"
          onClick={handleSubmitForm}
        >
          Thêm
        </ButtonCustom>
      </Grid>
    </Box>
  );
};

export default Create;
