import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useRef, useState } from "react";
import { ReactComponent as TrashIcon } from "assets/trash.svg";
import { Add, Search } from "@mui/icons-material";
import {
  InputAdornment,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import moment from "moment";
import Create from "./create";

import ButtonCustom from "components/button";
import { useDispatch } from "react-redux";
import { open } from "store/notificationSlice";
import Pagination from "@mui/material/Pagination";
import CustomerServices from "api/customer";
import ModalDelete from "./modalDelete";
import { Link } from "react-router-dom";

const Customer = () => {
  const [showDrawerCreate, setShowDrawerCreate] = useState(false);
  const [rows, setRows] = useState([]);
  const [form, setForm] = useState({});
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();
  const tableRef = useRef(null);
  const [widthColTable, setWidthColTable] = useState(0);
  const [page, setPage] = useState(1);
  const [modalDelete, setModalDelete] = useState(false);
  const [detail, setDetail] = useState();
  const [pagination, setPagination] = useState(1);

  const columns = [
    {
      field: "code",
      headerName: "Mã KH",
      width: widthColTable,
      filterable: false,
    },
    {
      field: "name",
      headerName: "Tên khách hàng",
      width: widthColTable,
    },
    {
      field: "phone",
      headerName: "Số điện thoại",
      width: widthColTable,
    },
    {
      field: "address",
      headerName: "Địa chỉ",
      width: widthColTable,
    },
    {
      field: "createdAt",
      headerName: "Ngày tạo",
      width: widthColTable,
      renderCell: (params) => {
        return (
          <div className="flex flex-col">
            <span className="text-[16px]">
              {moment(params.row.createdAt).format("DD/MM/YYYY")}
            </span>
            <span className="text-gray-500">
              {moment(params.row.createdAt).format("LT")}
            </span>
          </div>
        );
      },
    },

    {
      field: "detail",
      headerName: "Chi tiết",
      width: widthColTable,
      renderCell: (params) => {
        return (
          <Link to={`detail/${params?.id}`}>
          <ButtonCustom size="small">Chi tiết</ButtonCustom>
        </Link>
        );
      },
    },

    {
      field: "edit",
      headerName: "Xoá",
      width: widthColTable,
      renderCell: (params) => {
        return (
          <Button
            onClick={() => handleOpenModalDelete(params.row)}
            className="h-9 w-9"
          >
            <TrashIcon />
          </Button>
        );
      },
    },
  ];

  const handleShowDrawer = () => {
    setShowDrawerCreate(!showDrawerCreate);
  };

  const handleOpenModalDelete = (item) => {
    setModalDelete(!modalDelete);
    setDetail(item);
  };

  const handleDelete = async (id) => {
    try {
      await CustomerServices.deleteCustomer(id);
      dispatch(open({ isNoti: true, message: "Xoá thành công!" }));
      fetchCustomer();
      setModalDelete(false);
    } catch (error) {
      console.log(error);
      dispatch(
        open({
          isNoti: true,
          message: error.response?.data?.message,
        })
      );
    }
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setForm((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFormSubmit = async () => {
    try {
      const payload = {
        ...form,
      };
      await CustomerServices.createCustomer(payload);
      setShowDrawerCreate(false);
      setForm({});
      fetchCustomer();
      dispatch(open({ isNoti: true, message: "Tạo khách hàng thành công" }));
    } catch (error) {
      console.log(error);
      dispatch(open({ isNoti: true, message: error.response?.data?.message }));
    }
  };

  const fetchCustomer = async () => {
    try {
      const response = await CustomerServices.getCustomer({
        page: page,
        search: search ? search : null,
      });
      setPagination(
        Math.floor(response.data.total / (response.data.perPage + 1)) + 1
      );
      setRows(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setWidthColTable(
      tableRef.current?.offsetWidth / columns.length < 150
        ? 150
        : tableRef.current?.offsetWidth / columns.length
    );
  }, [tableRef.current?.offsetWidth]);

  useEffect(() => {
    fetchCustomer();
  }, [search, page]);

  return (
    <Box className="boxShadow rounded-2xl" pb={3}>
      <Typography variant="h5" fontWeight="bold">
        Danh sách khách hàng
      </Typography>
      <Stack
        direction="row"
        spacing={2}
        my={2}
        mr={4}
        justifyContent={"space-between"}
      >
        <TextField
          name="search"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          label="Search"
          placeholder="Search"
          className="w-[172px] md:w-[300px]"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />

        <ButtonCustom
          onClick={handleShowDrawer}
          size="small"
          classes="flex justify-center items-center md:gap-3 px-2 md:px-6"
        >
          <span>
            <Add />
          </span>
          Thêm khách hàng
        </ButtonCustom>
      </Stack>

      <Modal open={showDrawerCreate} onClose={handleShowDrawer}>
        <Create
          form={form}
          handleChangeInput={handleFormChange}
          handleSubmitForm={handleFormSubmit}
          handleClose={handleShowDrawer}
        />
      </Modal>

      <Modal open={modalDelete} onClose={handleOpenModalDelete}>
        <ModalDelete
          handleClose={handleOpenModalDelete}
          handleDelete={handleDelete}
          data={detail}
        />
      </Modal>

      <Box style={{ height: "65vh", width: "98%" }}>
        <DataGrid
          ref={tableRef}
          rowHeight={70}
          rows={rows}
          columns={columns}
          className="!border-none"
          hideFooterPagination
        />
      </Box>
      <Stack spacing={2} className="w-full flex items-center justify-center">
        <Pagination
          count={pagination}
          shape="rounded"
          page={page}
          onChange={(_, e) => setPage(e)}
        />
      </Stack>
    </Box>
  );
};

export default Customer;
