import React, { useEffect } from "react";

import AppLayout from "layout";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAuth, loginReducer, logout } from "store/authSlice";
import { RolesNotAdmin, USER_INFO } from "constant";
import { removeLocalStore, setUserStorage } from "utils/storage";
import AuthServices from "api/auth";

import NotFound from "page/404";
import Login from "page/auth/login";
import Notification from "components/notification";
import Order from "page/admin/order";
import Customer from "page/admin/customer";
import UsersPage from "page/admin/users";
import OrderDetail from "page/admin/order/order-detail";
import OrderNew from "page/admin/order/order-new";
import DetailUserPage from "page/admin/users/detail";
import Profile from "page/admin/profile";
import OrderForMember from "page/order";
import OrderDetailForMember from "page/order/order-detail";
import OrderNewForMember from "page/order/order-new";
import CustomerDetail from "page/admin/customer/details";

const routesAuth = [
  {
    key: 2,
    component: Order,
    path: "/admin/order",
    allowedRoles: ["admin"],
  },
  {
    key: 3,
    component: Customer,
    path: "/admin/customer",
    allowedRoles: ["admin"],
  },
  {
    key: 4,
    component: CustomerDetail,
    path: "/admin/customer/detail/:id",
    allowedRoles: ["admin"],
  },
  {
    key: 4,
    component: UsersPage,
    path: "/admin/user",
    allowedRoles: ["admin"],
  },
  {
    key: 5,
    component: OrderDetail,
    path: "/admin/order-detail/:id",
    allowedRoles: ["admin"],
  },
  {
    key: 6,
    component: OrderNew,
    path: "/admin/order-new",
    allowedRoles: ["admin"],
  },
  {
    key: 7,
    component: DetailUserPage,
    path: "/admin/user/detail/:id",
    allowedRoles: ["admin"],
  },
  {
    key: 8,
    component: Profile,
    path: "/admin/profile",
    allowedRoles: ["admin"],
  },
  {
    key: 9,
    component: OrderForMember,
    path: "/order",
    allowedRoles: RolesNotAdmin,
  },
  {
    key: 10,
    component: OrderDetailForMember,
    path: "/order-detail/:id",
    allowedRoles: RolesNotAdmin,
  },
  {
    key: 11,
    component: OrderNewForMember,
    path: "/order-new",
    allowedRoles: RolesNotAdmin,
  },
  {
    key: 12,
    component: Profile,
    path: "/profile",
    allowedRoles: RolesNotAdmin,
  },
];

const AppRouter = () => {
  const { isLogin, role } = useSelector(getAuth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  async function checkAuth() {
    const auth = localStorage.getItem("userInfo") || "{}";
    const userInfo = JSON.parse(auth);
    if (!userInfo?.token) {
      navigate(`/login`);
      return;
    }
    try {
      const response = await AuthServices.getMe();
      if (response?.data?.id) {
        const userStorage = {
          ...userInfo,
          user: response.data || null,
        };
        dispatch(
          loginReducer({
            isLogin: true,
            user: response.data,
            role: response.data.role,
          })
        );
        setUserStorage(USER_INFO, userStorage);
      } else {
        dispatch(logout());
        removeLocalStore(USER_INFO);
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    checkAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isLogin ? (
        <AppLayout>
          <Routes>
            {routesAuth?.map((route, index) => {
              const { path, component, allowedRoles } = route;
              if (allowedRoles.includes(role)) {
                return (
                  <Route key={index} exact path={path} Component={component} />
                );
              }

              return null;
            })}
            <Route
              path="/*"
              exact
              element={<Navigate to="/comingup" />}
              Component={NotFound}
            />
          </Routes>
        </AppLayout>
      ) : (
        <Routes>
          <Route key={0} exact path="/login" Component={Login} />
        </Routes>
      )}
      <Notification />
    </>
  );
};

export default AppRouter;
