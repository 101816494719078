import { AttachFileOutlined, Close } from "@mui/icons-material";
import { Autocomplete, Box, Grid, TextField, Typography } from "@mui/material";
import moment from "moment";

const ModalDetail = ({ form, handleClose }) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 650,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: 4,
    p: 4,
  };

  const PROCESS = {
    true: "Đã hoàn thành",
    false: "Chưa hoàn thành",
  };

  const CUSTOMER_CONFIRM = [
    { label: "KH đã duyệt", value: true },
    { label: "KH chưa duyệt", value: null },
  ];

  return (
    <Box sx={style} className="!w-[95%] md:!w-[650px]">
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography fontWeight={700} variant="h5">
            Chi tiết
          </Typography>
        </Grid>
        <Grid item xs={6} className="w-full flex justify-end items-center">
          <span
            className="z-50 border rounded-full p-2 cursor-pointer"
            onClick={handleClose}
          >
            <Close />
          </span>
        </Grid>
      </Grid>

      <Grid container spacing={5} className="!mt-[-8px]">
        <Grid item xs={6}>
          <TextField
            name="name"
            value={form?.user?.name}
            label="Tiến độ"
            variant="standard"
            className="w-full"
            focused={false}
          />
        </Grid>
        <Grid item xs={6}>
          {form?.type === "designer" ? (
            <Autocomplete
              name="customerConfirm"
              inputValue={
                CUSTOMER_CONFIRM?.filter(
                  (item) => item.value === form?.customerConfirm
                )[0]?.label || ""
              }
              getOptionLabel={(option) => option?.label}
              options={CUSTOMER_CONFIRM}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Trạng thái"
                  name="customerConfirm"
                  variant="standard"
                />
              )}
            />
          ) : (
            <TextField
              name="tick"
              value={PROCESS[form?.tick]}
              label="Trạng thái"
              variant="standard"
              className="w-full"
              focused={false}
            />
          )}
        </Grid>
      </Grid>

      {form?.type === "machineBendingWoker" ? (
        <Grid container spacing={5} className="!mt-[2px]">
          <Grid item xs={6}>
            <TextField
              value={`${moment(form?.updatedAt).format(
                "DD/MM/YYYY"
              )} - ${moment(form?.updatedAt).format("LT")}`}
              label="Ngày chỉnh sửa gần nhất"
              variant="standard"
              className="w-full !pt-2"
              focused={false}
            />
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={5} className="!mt-[2px]">
          <Grid item xs={12}>
            <TextField
              value={`${moment(form?.updatedAt).format(
                "DD/MM/YYYY"
              )} - ${moment(form?.updatedAt).format("LT")}`}
              label="Ngày chỉnh sửa gần nhất"
              variant="standard"
              className="w-full"
              focused={false}
            />
          </Grid>
        </Grid>
      )}

      <Grid container spacing={5} className="!mt-[2px]">
        <Grid item xs={12}>
          <TextField
            name="description"
            value={form?.description || ""}
            label="Mô tả"
            className="w-full"
            focused={false}
            multiline
            inputProps={{
              style: {
                height: "80px",
              },
            }}
          />
        </Grid>
      </Grid>

      <Grid container spacing={5} className="!mt-[2px]">
        <Grid item xs={12}>
          <Typography className="text-gray-500">Hình ảnh, file</Typography>
          <div className="flex flex-col gap-2 mt-4 -ml-2">
            {form?.files?.map((item) => (
              <div
                key={item}
                className="flex gap-1 cursor-pointer border rounded-xl p-1"
              >
                <span>
                  <AttachFileOutlined fontSize="small" />
                </span>
                <a
                  href={item}
                  target="_blank"
                  className="text-gray-500 w-full truncate"
                  rel="noreferrer"
                >
                  {item.split("/").splice(6).join("")}
                </a>
              </div>
            ))}
          </div>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ModalDetail;
