import { Box, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import UserServices from "api/user";
import ButtonCustom from "components/button";
import { LOCATION } from "constant";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { open } from "store/notificationSlice";

const DetailUserPage = () => {
  const { id } = useParams();
  const [data, setData] = useState({});
  const [formPass, setFormPass] = useState({});
  const dispatch = useDispatch();
  const fetchDataUser = async () => {
    try {
      const response = await UserServices.getUserById(id);
      setData(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangeInput = async (e) => {
    const { name, value } = e.target
    if (name === 'location') {
      await UserServices.updateUser(id, {
        location: value
      })
      dispatch(
        open({
          isNoti: true,
          message: "Cập nhật khu vực cho nhân viên thành công",
        })
      );
      setData({...data, location: value})
    } else {
      setFormPass((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
    
  };

  const updatePassword = async (id) => {
    try {
      if (!formPass?.password || !formPass.confirmPassword) {
        dispatch(
          open({
            isNoti: true,
            message: "Xin hãy nhập mật khẩu",
          })
        );
        return;
      }
      if (formPass?.password === formPass.confirmPassword) {
        await UserServices.updateUser(id, {
          password: formPass.password,
        });
        dispatch(
          open({
            isNoti: true,
            message: "Mật khẩu cập nhật thành công",
          })
        );
      } else {
        dispatch(
          open({
            isNoti: true,
            message: "Mật khẩu không khớp, hãy nhập lại trong ô mật khẩu và ô nhập lại mật khẩu, hãy chắc chắc chúng khớp với nhau.",
          })
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchDataUser();
  }, [id]);
  return (
    <Box className="boxShadow rounded-2xl" pb={3}>
      <Typography variant="h5" fontWeight="bold">
        Thông tin nhân sự
      </Typography>
      <div className="p-2">
        <div className="mb-4">
          <div className="w-[184px] h-[173px] border mb-2">
            <img src={data?.avatar} alt="" className="" />
          </div>
          <Typography className="text-xl" variant="p" fontWeight="bold">
            {data?.name}
          </Typography>
        </div>

        <div className="py-4 md:border-y flex flex-wrap">
          <div className="w-full md:w-1/3 border-b md:mt-0 mt-3 md:border-none">
            <div className="text-base font-medium text-[#A7A19E]">
              Họ và tên
            </div>
            <div className="font-medium text-xl">{data?.name}</div>
          </div>

          <div className="w-full md:w-1/3 border-b md:mt-0 mt-3 md:border-none">
            <FormControl fullWidth>
            <InputLabel id="location">Khu vực</InputLabel>
              <Select
                labelId="location"
                name="location"
                id="location"
                value={data?.location || 'south'}
                label="location"
                className="w-full"
                onChange={handleChangeInput}
              >
                {Object.keys(LOCATION).map((k) => (
                  <MenuItem key={k} value={k}>
                    {LOCATION[k]}
                  </MenuItem>
                ))}
              </Select>
          </FormControl>
          </div>

          <div className="w-full border-b md:mt-0 mt-3 md:border-none">
            <div className="text-base font-medium text-[#A7A19E]">
              Phân quyền
            </div>
            <div className="font-medium text-xl">{data?.role}</div>
          </div>
        </div>

        <div className="mt-4">
          <Typography className="text-xl" variant="p" fontWeight="bold">
            Change Password
          </Typography>

          <div className="flex flex-wrap gap-2 mt-4">
            <TextField
              label="Mật khẩu"
              variant="outlined"
              type="password"
              name="password"
              className="w-1/3"
              defaultValue="password"
              onChange={handleChangeInput}
              value={formPass?.password}
            />
            <TextField
              label="Nhập lại mật khẩu"
              variant="outlined"
              type="password"
              name="confirmPassword"
              className="w-1/3"
              defaultValue="password"
              onChange={handleChangeInput}
              value={formPass?.confirmPassword}
            />
          </div>
        </div>

        <ButtonCustom
          onClick={() => updatePassword(id)}
          classes="w-[166px] h-[50px] text-base mt-4"
          size="small"
        >
          Update Password
        </ButtonCustom>
      </div>
    </Box>
  );
};

export default DetailUserPage;
