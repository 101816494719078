import clsx from "clsx";
import React from "react";

const ButtonCustom = (props) => {
  const {
    classes,
    size = "medium",
    variant = "primary",
    onClick,
    children,
  } = props;

  const btnVariant = {
    primary: "bg-gradient-primary !text-white",
    none: "bg-gray-200 !text-black",
  };

  const sizeBtn = {
    small: "text-sm",
    medium: "text-xl",
    large: "text-2xl",
  };

  const classBtn = clsx(btnVariant[variant], sizeBtn[size], classes);
  return (
    <React.Fragment>
      <button
        onClick={onClick}
        className={`px-4 py-1.5 rounded-lg opacity-95 hover:opacity-100 ${classBtn}`}
      >
        {children}
      </button>
    </React.Fragment>
  );
};

export default ButtonCustom;
