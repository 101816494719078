import axios from "../api";

export const getNotifications = async (params) =>
  axios.get("/notifications", {
    params: { ...params },
  });
export const readNotification = async (id) =>
  axios.patch(`/notifications/read/${id}`);

export const seeNotification = async () => axios.get("/notifications/see");
export const readAllNotification = () => axios.patch("/notifications/read-all");

const NotificationServices = {
  getNotifications,
  readNotification,
  seeNotification,
  readAllNotification,
};

export default NotificationServices;
