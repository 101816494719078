/* eslint-disable react/jsx-no-duplicate-props */
import { Add, Search } from "@mui/icons-material";
import {
  Grid,
  InputAdornment,
  Modal,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import { useEffect, useRef, useState } from "react";

import Pagination from "@mui/material/Pagination";
import OrderServices from "api/order";
import ButtonCustom from "components/button";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { ReactComponent as Filter } from "assets/setting-4.svg";
import { CONTACT_FORM, SHAPE, TYPE_SHAPE } from "constant";

const DEFAULT_DATE = moment().format('YYYY-MM-DD')

const OrderForMember = () => {
  const [showDrawerCreate, setShowDrawerCreate] = useState(false);
  const [rows, setRows] = useState([]);
  const [search, setSearch] = useState({});
  const tableRef = useRef(null);
  const { role } = useSelector((state) => state.auth);
  const [value, setValue] = useState(role === 'creator' ? 'new' : 'pending');
  const [page, setPage] = useState(1);
  const [modalFilter, setModalFilter] = useState(false);
  const [pagination, setPagination] = useState(1);
  const navigate = useNavigate();

  const orderStatuses = [
    {
      status: 'new',
      label: 'Đơn hàng mới'
    },
    {
      status: 'pending',
      label: 'Đang tiến hành'
    },
    {
      status: 'completed',
      label: 'Đã hoàn tất'
    }
  ]
  let params = useLocation();


  const getRowClassName = (params) => {
    const className = params.row.highlighted ? 'bg-yellow-400' : '';
    return className
  }

  const handleChange = (_, newValue) => {
    setValue(newValue);
    navigate(`/order?status=${newValue}`);
  };

  const columns = [
    {
      field: "code",
      width: 120,
      headerName: "Mã đơn hàng",
      filterable: false,
    },
    {
      field: "orderName",
      width: 250,
      headerName: "Tên đơn hàng",
      filterable: false,
      renderCell: ({ value }) => (
        <span style={{ overflow: "visible", whiteSpace: 'normal' }}>
          {value}
        </span>
      )
    },
    {
      width: 80,
      field: "quantity",
      headerName: "SL",
      filterable: false,
    },
    {
      field: "codeCustomer",
      headerName: "Mã KH",
      width: 100,
      filterable: false,
      renderCell: (params) => {
        return <p>{params.row?.customer?.code}</p>;
      },
    },

    {
      field: "shape",
      headerName: "Khuôn",
      width: 100,
      filterable: false,
      renderCell: (params) => {
        return <p>{SHAPE[params.row?.shape]}</p>;
      },
    },
    {
      field: "typeShape",
      headerName: "Loại Khuôn",
      width: 120,
      filterable: false,
      renderCell: (params) => {
        return <p>{TYPE_SHAPE[params.row?.typeShape]}</p>;
      },
    },
    {
      field: "contactForm",
      headerName: "Hình thức nhận khuôn",
      width: 100,
      filterable: false,
      renderCell: (params) => {
        return <p>{CONTACT_FORM[params.row?.contactForm]}</p>;
      },
    },
    {
      field: "createdAt",
      headerName: "Ngày tạo",
      width: 100,
      renderCell: (params) => {
        return (
          <div className="flex flex-col">
            <span className="text-[16px]">
              {moment(params.row.createdAt).format("DD/MM/YYYY")}
            </span>
            <span className="text-gray-500">
              {moment(params.row.createdAt).format("LT")}
            </span>
          </div>
        );
      },
    },
    {
      field: "detail",
      headerName: "Chi tiết",
      width: 150,
      renderCell: (params) => {
        return (
          <ButtonCustom size="small">
            <Link to={`/order-detail/${params.row.id}`}>Chi tiết</Link>
          </ButtonCustom>
        );
      },
    },
  ];

  const handleShowDrawer = () => {
    setShowDrawerCreate(!showDrawerCreate);
  };

  const fetchOrder = async () => {
    try {
      const response = await OrderServices.getOrderMember({
        page: page,
        search: search?.key ? search?.key : null,
        orderStatus: value,
        fromDate: search?.fromDate
          ? moment(search?.fromDate).startOf("day").format()
          : null,
        toDate: search?.toDate
          ? moment(search?.toDate).endOf("day").format()
          : null,
      });
      setPagination(
        Math.floor(response.data.total / (response.data.perPage + 1)) + 1
      );
      setRows(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const openModalFilter = () => {
    setModalFilter(!modalFilter);
  };

  useEffect(() => {
    fetchOrder();
    setValue(params?.search?.slice(8) || 'pending')
  }, [search?.key, search?.fromDate, search?.toDate, page, value]);

  return (
    <Box className="boxShadow rounded-2xl" pb={3}>
      <Typography variant="h5" fontWeight="bold">
        Danh sách đơn hàng
      </Typography>

      <Stack
        direction="row"
        spacing={2}
        my={2}
        mr={4}
        justifyContent={"space-between"}
      >
        <Grid className="hidden gap-4 lg:flex">
          <TextField
            name="key"
            value={search?.key}
            onChange={(e) => setSearch({ ...search, key: e.target.value })}
            label="Search"
            placeholder="Search"
            className="w-[200px] xl:w-[300px]"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            name="fromDate"
            value={search?.fromDate ?? DEFAULT_DATE}
            defaultValue={DEFAULT_DATE}
            onChange={(e) => setSearch({ ...search, fromDate: e.target.value })}
            label="Từ ngày"
            className="w-[200px] xl:w-[300px]"
            type="date"
          />
          <TextField
            name="toDate"
            value={search?.toDate}
            onChange={(e) => setSearch({ ...search, toDate: e.target.value })}
            label="Đến ngày"
            className="w-[200px] xl:w-[300px]"
            type="date"
          />
        </Grid>

        <Modal open={modalFilter} onClose={openModalFilter}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -70%)",
              width: 800,
              bgcolor: "background.paper",
              boxShadow: 24,
              borderRadius: 4,
              p: 4,
            }}
            className="!w-[95%] !md:w-[650px]"
          >
            <Typography variant="h6" className="!mb-6">
              Tìm kiếm đơn hàng
            </Typography>
            <Grid className="gap-4 flex !flex-col">
              <TextField
                name="key"
                value={search?.key}
                onChange={(e) => setSearch({ ...search, key: e.target.value })}
                label="Search"
                placeholder="Search"
                className="w-full"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                name="fromDate"
                value={search?.fromDate}
                onChange={(e) =>
                  setSearch({ ...search, fromDate: e.target.value })
                }
                label="Từ ngày"
                className="w-full"
                type="date"
              />
              <TextField
                name="toDate"
                value={search?.toDate}
                onChange={(e) =>
                  setSearch({ ...search, toDate: e.target.value })
                }
                label="Đến ngày"
                className="w-full"
                type="date"
              />
            </Grid>
          </Box>
        </Modal>

        <ButtonCustom
          size="small"
          variant="none"
          classes="!ml-0 block lg:hidden"
          onClick={openModalFilter}
        >
          <Link className="w-full h-full flex gap-1 justify-center items-center">
            <span>
              <Filter />
            </span>
             Filter
          </Link>
        </ButtonCustom>

        {role === "creator" && (
          <ButtonCustom onClick={handleShowDrawer} size="small">
            <Link
              to="/order-new"
              className="w-full h-full flex gap-1 lg:gap-3 px-0 lg:px-2 justify-center items-center"
            >
              <span>
                <Add />
              </span>
                Thêm đơn hàng
            </Link>
          </ButtonCustom>
        )}
      </Stack>

      <Stack
        direction="row"
        spacing={2}
        my={4}
        mr={4}
        justifyContent={"space-between"}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="secondary"
          indicatorColor="transparent"
        >
          {orderStatuses.filter(orderStatus => {
            if (orderStatus.status === 'new' && (role === 'admin' || role === 'creator')) {
              return true
            } else if (orderStatus.status === 'new') {
              return false
            }
            return  true
          }).map(orderStatus => {
            return (<Tab
            key={orderStatus.status}
            value={orderStatus.status}
            label={orderStatus.label}
            className="md:!text-[14px] !text-[11px]"
          />)
          })}
          
        </Tabs>
      </Stack>

      <Box style={{ height: "58vh", width: "85%" }}>
        <DataGrid
          ref={tableRef}
          rowHeight={150}
          rows={rows}
          columns={columns}
          className="!border-none !overflow-x-hidden"
          hideFooterPagination
          getRowClassName={getRowClassName}
        />
      </Box>
      <Stack spacing={2} className="w-full flex items-center justify-center">
        <Pagination
          count={pagination}
          shape="rounded"
          page={page}
          onChange={(_, e) => setPage(e)}
        />
      </Stack>
    </Box>
  );
};

export default OrderForMember;
