import { Box, Snackbar } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { close } from "store/notificationSlice";

const Notification = () => {
  const { isNoti, message } = useSelector((state) => state.notification);
  const dispatch = useDispatch()
  return (
    <>
      <Box sx={{ width: 500 }}>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={isNoti}
          onClose={() => dispatch(close())}
          message={message}
          key={message}
        />
      </Box>
    </>
  );
};

export default Notification;
