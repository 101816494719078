import React from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";

import {
  Box,
  Drawer,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
} from "@mui/material";
import { ReactComponent as Logo } from "assets/logo.svg";
import { getAuth } from "store/authSlice";
import { ReactComponent as BoxIcon } from "assets/box.svg";
import { ReactComponent as TwoUserIcon } from "assets/profile-2user.svg";
import { ReactComponent as Octagon } from "assets/user-octagon.svg";
import { ReactComponent as CloseIcon } from "assets/close.svg";
import { RolesNotAdmin } from "constant";

const bgMenu = `linear-gradient(95.47deg, rgba(236, 31, 36, 0.15) -3.34%, rgba(46, 49, 145, 0.15) 104.25%, rgba(45, 49, 146, 0.025) 104.26%)`;

const routes = {
  admin: [
    {
      route: "admin/order",
      label: "Quản lý đơn hàng",
      icon: ({ isActive }) => <BoxIcon className={isActive && "strokeRed"} />,
      permission: ["admin"],
    },
    {
      route: "admin/user",
      label: "Nhân sự",
      icon: ({ isActive }) => (
        <TwoUserIcon className={isActive && "strokeRed"} fill="#fff" />
      ),
      permission: ["admin"],
    },
    {
      route: "admin/customer",
      label: "Khách hàng",
      icon: ({ isActive }) => <Octagon className={isActive && "strokeRed"} />,
      permission: ["admin"],
    },
  ],
  creator: [
    {
      route: "order",
      label: "Quản lý đơn hàng",
      icon: ({ isActive }) => <BoxIcon className={isActive && "strokeRed"} />,
      permission: RolesNotAdmin,
    },
  ],
};

export default function AppSidebar({ windowSize, roll, setRoll }) {
  const { isLogin, role } = useSelector(getAuth);
  const { pathname } = useLocation();

  const renderRoute = (route) => {
    const isActive = pathname.slice(1, pathname.length).includes(route.route);
    return (
      <React.Fragment key={route.label}>
        <ListItem disablePadding className="px-4 py-1.5">
          <ListItemButton
            component={Link}
            to={`/${route.route}`}
            className="!rounded-e-lg"
            style={{
              background: isActive && bgMenu,
              color: isActive && "#EC1F24",
              borderLeft: isActive
                ? "3px solid #EC1F24"
                : "3px solid transparent",
            }}
          >
            <ListItemIcon className="!min-w-[20px] w-[24px] mr-4">
              {route.icon({ isActive: isActive })}
            </ListItemIcon>
            <ListItemText primary={route.label} className="whitespace-nowrap" />
          </ListItemButton>
        </ListItem>
      </React.Fragment>
    );
  };

  return (
    <>
      <Drawer
        sx={{
          width: roll ? 260 : 0,
          flexShrink: windowSize < 720 ? 260 : 0,
          "& .MuiDrawer-paper": {
            width: roll ? 260 : 0,
            boxSizing: "border-box",
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <Toolbar>
          <div
            variant="h4"
            className="w-full !font-bold text-center flex justify-start items-center"
          >
            <Link
              className="w-[120px] -ml-3"
              to={role === "admin" ? "/admin/order" : "/order"}
            >
              <Logo />
            </Link>
          </div>
          {windowSize < 720 ? (
            <div className="cursor-pointer" onClick={() => setRoll(false)}>
              <CloseIcon />
            </div>
          ) : (
            ""
          )}
        </Toolbar>
        {/* <Divider /> */}
        <Box className="mt-4">
          {isLogin ? (
            role === "admin" ? (
              routes[role]?.map((route) => (
                <React.Fragment key={route.label}>
                  {renderRoute(route)}
                </React.Fragment>
              ))
            ) : (
              routes["creator"]?.map((route) => (
                <React.Fragment key={route.label}>
                  {renderRoute(route)}
                </React.Fragment>
              ))
            )
          ) : (
            <></>
          )}
        </Box>
      </Drawer>
    </>
  );
}
