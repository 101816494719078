import axios from ".";

export const getUser = async (params) =>
  axios.get("/admin/users", {
    params: { perPage: 10, page: 1, orderBy: "asc", ...params },
  });

export const getSearchUsers = async () =>
axios.get("/users", {
  params: { search: '' },
});

export const getUserById = async (id) => axios.get(`/admin/users/${id}`);

export const createUser = async (payload) =>
  axios.post("/admin/users", payload);

export const updateUser = async (id, payload) =>
  axios.patch(`/admin/users/${id}`, payload);

export const deleteUser = async (id) => axios.delete(`/admin/users/${id}`);

const UserServices = {
  getUser,
  createUser,
  updateUser,
  getUserById,
  deleteUser,
};

export default UserServices;
