import { Typography } from "@mui/material";
import { ReactComponent as CheckIcons } from "assets/checks.svg";
import { ReactComponent as BoxIcon } from "assets/box_notif.svg";
import { ReactComponent as CheckIcon } from "assets/check.svg";
import moment from "moment";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const ModalNotification = ({
  listData,
  handleRead,
  handleReadAllNotifications,
  setPerPage,
}) => {
  const { role } = useSelector((state) => state.auth);

  return (
    <div className="w-full md:w-[396px] h-[367px]">
      <div className="flex flex-wrap p-4 border-b justify-between">
        <div className="w-1/2">
          <Typography className="!font-bold">Thông báo</Typography>
        </div>
        <button className="flex" onClick={handleReadAllNotifications}>
          <CheckIcons />
          <Typography className="!font-medium">Đã đọc</Typography>
        </button>
      </div>

      <div className="">
        {listData?.data?.map((e, i) => (
          <div
            key={i}
            onClick={() => handleRead(e?.id)}
            className="p-4 flex cursor-pointer hover:bg-[#e9e9e9]"
          >
            {e?.isRead ? (
              <div className="w-[55px] h-[40px] md:h-[48px] rounded-full flex items-center justify-center bg-[#2d31921a]">
                <CheckIcon />
              </div>
            ) : (
              <div className="w-[55px] h-[40px] md:h-[48px] rounded-full flex items-center justify-center bg-gradient-light">
                <BoxIcon />
              </div>
            )}

            <div className="w-full pl-4">
              <div className="flex justify-between items-center gap-2">
                <p className="font-bold text-base">{e?.data?.title}</p>
                <Link
                  to={
                    role === "admin"
                      ? `/admin/order-detail/${e?.data?.orderId}`
                      : `/order-detail/${e?.data?.orderId}`
                  }
                  className="text-gray-500 underline !text-[13px] z-50"
                >
                  Chi tiết
                </Link>
              </div>
              <p className="text-[#718096] font-thin text-xs">
                {e?.data?.message}
              </p>
              <p className="mt-1 text-[#718096] font-semibold text-xs">
                {moment(e?.data?.tickTime).format("LT")}
              </p>
            </div>
          </div>
        ))}

        {listData?.data?.length ? (
          listData?.page * listData?.perPage < listData?.total ? (
            <p
              className="text-center cursor-pointer py-2 text-[#208fff]"
              onClick={() => setPerPage((prev) => prev + 10)}
            >
              Xem thêm ...
            </p>
          ) : (
            ""
          )
        ) : (
          <p className="text-center cursor-pointer py-2 text-[#929292]">
            Không có thông báo
          </p>
        )}
      </div>
    </div>
  );
};

export default ModalNotification;
