import { Box, Grid, Stack, TextField, Typography } from "@mui/material";
import CustomerServices from "api/customer";
import ButtonCustom from "components/button";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { open } from "store/notificationSlice";

const CustomerDetail = () => {
  const { id } = useParams()
  const [form, setForm] = useState({})
  const dispatch = useDispatch()
  const fetchDataCustomer = async () => {
    try {
      const response = await CustomerServices.getCustomerById(id);
      const data = response.data
      setForm({
        ...form,
        code: data?.code,
        name: data?.name,
        address: data?.address,
        phone: data?.phone,
        note: data?.note
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangeForm = (e) => {
    let { name, value } = e.target;
    if (name === 'quantity') value = Number(value)
    setForm({ ...form, [name]: value });
  };

  const handleUpdate = async () => {
    try {
      await CustomerServices.updateCustomer(id, {
        ...form,
      });
      dispatch(
        open({ isNoti: true, message: "Cập nhật khách hàng thành công!" })
      );
    } catch (error) {
      console.log(error);
      dispatch(open({ isNoti: true, message: error.response?.data?.message }));
    }
  };

  useEffect(() => {
    fetchDataCustomer();
  }, [id]);

  return (
    <React.Fragment>
      <Box className="boxShadow rounded-2xl my-3" pb={3}>
        <Typography variant="h5" fontWeight="bold">
          Chi tiết khách hàng
        </Typography>
        <Stack p={2} pr={3} className="flex gap-3 my-3 mb-8">
          <Grid container spacing={{ xs: 2, md: 8 }}>
            <Grid item xs={6}>            
              <TextField
              className="w-full h-[60px]"
              id="standard-basic"
              label="Mã khách hàng"
              variant="standard"
              value={form.code ?? ''}
              aria-readonly
              name="code"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              className="w-full h-[60px]"
              id="standard-basic"
              label="Tên khách hàng"
              variant="standard"
              value={form.name ?? ''}
              name="name"
              onChange={handleChangeForm}
            />
          </Grid>
        </Grid>
        <Grid container spacing={{ xs: 2, md: 8 }}>
          <Grid item xs={6}>
            <TextField
              className="w-full h-[60px]"
              id="standard-basic"
              label="Địa chỉ"
              variant="standard"
              value={form.address ?? ''}
              onChange={handleChangeForm}
              name="address"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              className="w-full h-[60px]"
              id="standard-basic"
              label="Điện thoại"
              variant="standard"
              value={form.phone ?? ''}
              name="phone"
              onChange={handleChangeForm}
            />
          </Grid>
        </Grid>
        <Grid container spacing={{ xs: 2, md: 8 }}>
          <Grid item xs={12}>
            <TextField
              className="w-full h-[60px]"
              id="standard-basic"
              label="Lưu ý khách hàng"
              value={form?.note ?? ''}
              name="note"
              onChange={handleChangeForm}
              multiline
              inputProps={{
                style: {
                  height: "80px",
                },
              }}
            />
        </Grid>
       </Grid> 
      </Stack>
      <Stack p={2} pr={3} className="flex gap-3 my-3 mb-8">
        <ButtonCustom
          onClick={handleUpdate}
          classes="w-[166px] h-[50px] text-base mt-4"
          size="small"
        >
          Lưu
        </ButtonCustom>
      </Stack>
    </Box>
  </React.Fragment>
  );
};

export default CustomerDetail;
