import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useRef, useState } from "react";
import { ReactComponent as TrashIcon } from "assets/trash.svg";
import { Add, Search } from "@mui/icons-material";
import {
  InputAdornment,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Create from "./create";

import UserServices from "api/user";
import ButtonCustom from "components/button";
import { useDispatch } from "react-redux";
import { open } from "store/notificationSlice";
import Pagination from "@mui/material/Pagination";
import Delete from "./delete";
import { Link } from "react-router-dom";

const UsersPage = () => {
  const dispatch = useDispatch();
  const tableRef = useRef(null);

  const [showDrawerCreate, setShowDrawerCreate] = useState(false);
  const [showDrawerDelete, setShowDrawerDelete] = useState(false);
  const [rows, setRows] = useState([]);
  const [form, setForm] = useState({});
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [formDelete, setFormDelete] = useState({});
  const [widthColTable, setWidthColTable] = useState(0);
  const [pagination, setPagination] = useState(1);

  const listRole = [
    {
      value: "admin",
      label: "Quản lý",
    },
    {
      value: "creator",
      label: "Người tạo đơn",
    },
    {
      value: "designer",
      label: "Thiết kế",
    },
    {
      value: "lazerWorker",
      label: "Thợ lazer",
    },
    {
      value: "machineBendingWoker",
      label: "Thợ uốn máy",
    },
    {
      value: "handBendingWorker",
      label: "Thợ thủ công",
    },
    {
      value: "qc",
      label: "QC",
    },
    {
      value: "shipper",
      label: "Giao hàng",
    },
  ];

  const columns = [
    {
      field: "code",
      headerName: "Mã nhân sự",
      width: widthColTable,
      filterable: false,
    },
    {
      field: "name",
      headerName: "Tên nhân sự",
      width: widthColTable,
    },
    {
      field: "role",
      headerName: "Chức danh",
      width: widthColTable,
      renderCell: (params) =>
        listRole.map((e) => {
          if (e.value === params?.row?.role) return e.label;
        }),
    },
    {
      field: "email",
      headerName: "Email Address",
      width: widthColTable,
    },
    {
      field: "detail",
      headerName: "Chi tiết",
      width: widthColTable,
      renderCell: (params) => {
        return (
          <Link to={`detail/${params?.id}`}>
            <ButtonCustom size="small">Chi tiết</ButtonCustom>
          </Link>
        );
      },
    },
    {
      field: "edit",
      headerName: "Xoá",
      width: widthColTable,
      renderCell: (params) => {
        return (
          <Button onClick={() => openModalDelete(params)} className="h-9 w-9">
            <TrashIcon />
          </Button>
        );
      },
    },
  ];

  useEffect(() => {
    setWidthColTable(
      tableRef.current?.offsetWidth / columns.length < 150
        ? 150
        : tableRef.current?.offsetWidth / columns.length
    );
  }, [tableRef.current?.offsetWidth]);

  const handleShowDrawer = () => {
    setShowDrawerCreate(!showDrawerCreate);
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setForm((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFormSubmit = async () => {
    try {
      const payload = {
        ...form,
      };
      if (!payload?.email || !payload?.email.trim()) {
        delete payload.email
      }
      await UserServices.createUser(payload);
      setShowDrawerCreate(false);
      setForm({});
      fetchCustomer();
      dispatch(open({ isNoti: true, message: "Tạo nhân viên thành công" }));
    } catch (error) {
      dispatch(open({ isNoti: true, message: error.response?.data?.message }));
    }
  };

  const fetchCustomer = async () => {
    try {
      const response = await UserServices.getUser({
        page: page,
        perPage: 10,
        search: search ? search : null,
      });
      setPagination(
        Math.floor(response.data.total / (response.data.perPage + 1)) + 1
      );
      setRows(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const openModalDelete = (params) => {
    setFormDelete({
      id: params?.id,
      name: params?.row?.name,
    });
    setShowDrawerDelete(true);
  };

  const CloseModalDelete = () => {
    setFormDelete({});
    setShowDrawerDelete(!showDrawerDelete);
  };

  const handleDeleteUser = async () => {
    try {
      if (!formDelete?.id) return;
      await UserServices.deleteUser(formDelete?.id);
      dispatch(open({ isNoti: true, message: "Delete user successfully!!" }));
      fetchCustomer();
      setFormDelete({});
      setShowDrawerDelete(false);
    } catch (err) {
      dispatch(open({ isNoti: true, message: err.response.data.message }));
    }
  };

  useEffect(() => {
    fetchCustomer();
  }, [search, page]);

  return (
    <Box className="boxShadow rounded-2xl" pb={3}>
      <Typography variant="h5" fontWeight="bold">
        Danh sách nhân sự
      </Typography>
      <Stack
        direction="row"
        spacing={2}
        my={2}
        mr={4}
        justifyContent={"space-between"}
      >
        <TextField
          name="search"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          label="Search"
          placeholder="Search"
          className="w-[190px] md:w-[300px]"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />

        <ButtonCustom
          onClick={handleShowDrawer}
          size="small"
          classes="flex justify-center items-center md:gap-3 px-2 md:px-6"
        >
          <span>
            <Add />
          </span>
          Thêm nhân sự
        </ButtonCustom>
      </Stack>

      <Modal open={showDrawerCreate} onClose={handleShowDrawer}>
        <Create
          form={form}
          handleChangeInput={handleFormChange}
          handleSubmitForm={handleFormSubmit}
          handleClose={handleShowDrawer}
          listRole={listRole}
        />
      </Modal>

      <Modal open={showDrawerDelete} onClose={CloseModalDelete}>
        <Delete
          handleClose={() => setShowDrawerDelete(!showDrawerDelete)}
          formDelete={formDelete}
          handleDeleteUser={handleDeleteUser}
        />
      </Modal>

      <Box style={{ height: "65vh", width: "98%" }}>
        <DataGrid
          ref={tableRef}
          rowHeight={70}
          rows={rows}
          columns={columns}
          className="!border-none"
          hideFooterPagination
        />
      </Box>
      <Stack spacing={2} className="w-full flex items-center justify-center">
        <Pagination
          count={pagination}
          shape="rounded"
          page={page}
          onChange={(_, e) => setPage(e)}
        />
      </Stack>
    </Box>
  );
};

export default UsersPage;
