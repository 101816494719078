export const getUserStorage = (key) => {
	if (typeof window !== "undefined") {
		const value = localStorage?.getItem(key);
		if (value) return JSON.parse(value);
	}
	return null;
};

export const setUserStorage = (key, value) => {
	if (typeof window !== "undefined") {
		localStorage?.setItem(key, JSON.stringify(value));
	}
};

export const removeLocalStore = (key) => {
	if (typeof window !== "undefined") {
		localStorage?.removeItem(key);
	}
};
