import Dropdown from "@mui/joy/Dropdown";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import {
  AppBar,
  Avatar,
  Badge,
  IconButton,
  Popover,
  Stack,
} from "@mui/material";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import AppSidebar from "./sidebar";

import {
  LogoutOutlined,
  NotificationsOutlined,
  Person,
} from "@mui/icons-material";
import Menu from "@mui/joy/Menu";
import MenuButton from "@mui/joy/MenuButton";
import MenuItem from "@mui/joy/MenuItem";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAuth, logout } from "store/authSlice";
import { useEffect, useState } from "react";
import { ReactComponent as MenuIcon } from "assets/menu.svg";
import { BASE_URL, VietnameseRoles } from "constant";
import ModalNotification from "components/modalNotification";
import NotificationServices from "api/notification";

function getWindowSize() {
  const { innerWidth } = window;
  return innerWidth;
}

const AppLayout = ({ children }) => {
  const { role, user } = useSelector(getAuth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [roll, setRoll] = useState(false);
  const [listData, setListData] = useState([]);
  const [reloadNotification, setReloadNotification] = useState();
  const [perPage, setPerPage] = useState(10);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleReadAllNotifications = async () => {
    try {
      await NotificationServices.readAllNotification();
      fetchNotification();
    } catch (err) {
      dispatch(open({ isNoti: true, message: err.response.data.message }));
    }
  };

  const handleLogout = () => {
    dispatch(logout());
    navigate("/login");
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleRead = async (id) => {
    try {
      await NotificationServices.readNotification(id);
      fetchNotification();
    } catch (err) {
      dispatch(open({ isNoti: true, message: err.response?.data?.message }));
    }
  };

  const fetchNotification = async (perPage) => {
    try {
      const { data } = await NotificationServices.getNotifications({ perPage });
      setListData(data);
      if (data.unread) {
        document.title = `(${data.unread}) PNK`;
      }
    } catch (err) {
      dispatch(open({ isNoti: true, message: err.response?.data?.message }));
    }
  };

  useEffect(() => {
    const url = `${BASE_URL}sse`;
    const eventSource = new EventSource(url);
    eventSource.onmessage = ({ data }) => {
      setReloadNotification(JSON.parse(data));
      fetchNotification();
    };
  }, []);

  useEffect(() => {
    fetchNotification(perPage);
  }, [reloadNotification, perPage]);

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
      getWindowSize() < 720 ? setRoll(false) : setRoll(true);
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    windowSize < 720 ? setRoll(false) : setRoll(true);
  }, []);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className="!bg-[#fff] w-full flex justify-between py-2"
        sx={{
          // eslint-disable-next-line no-template-curly-in-string
          width: `${windowSize < 720 ? "100%" : "calc(100% - ${240}px"}`,
          ml: `${240}px`,
        }}
      >
        <Toolbar>
          {roll ? (
            ""
          ) : (
            <div
              className="cursor-pointer z-[99999]"
              onClick={() => setRoll(true)}
            >
              <MenuIcon />
            </div>
          )}
          <Stack
            direction="row"
            justifyContent="flex-start"
            gap={2}
            alignItems="center"
            flexGrow={1}
          >
            <Stack direction="row"></Stack>
          </Stack>
          <Stack
            direction="row"
            justifyContent="flex-end"
            gap={2}
            alignItems="center"
            flexGrow={1}
          >
            <Stack direction="row" className="bg-[#e5e5e5] rounded-full">
              <IconButton
                aria-describedby={id}
                variant="contained"
                onClick={handleClick}
                aria-label="show 4 new notifications"
              >
                {listData?.unread ? (
                  <Badge badgeContent={listData?.unread} color="error">
                    <NotificationsOutlined color="#000" />
                  </Badge>
                ) : (
                  <NotificationsOutlined color="#000" />
                )}
              </IconButton>

              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                className="mt-2"
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <ModalNotification
                  listData={listData}
                  handleRead={handleRead}
                  handleReadAllNotifications={handleReadAllNotifications}
                  setPerPage={setPerPage}
                />
              </Popover>
            </Stack>
            <Stack direction="row">
              <Dropdown>
                <MenuButton
                  slots={{ root: IconButton }}
                  slotProps={{ root: { variant: "plain", color: "neutral" } }}
                  sx={{ borderRadius: 20 }}
                >
                  <IconButton className="!rounded-full gap-2 !bg-gradient-light flex !justify-between max-w-[250px]">
                    {user?.avatar ? (
                      <div
                        className="rounded-full h-8 min-w-[32px] border border-black"
                        style={{
                          backgroundImage: `url('${user?.avatar}')`,
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                        }}
                      ></div>
                    ) : (
                      <div className="rounded-full h-8 min-w-[32px] bg-black"></div>
                    )}
                    <p
                      className="flex-[240px] text-[14px] font-bold text-black px-2 whitespace-nowrap truncate"
                      title={`${user?.name} - ${VietnameseRoles[user?.role]}`}
                    >
                      {user?.name} ({VietnameseRoles[user?.role]})
                    </p>
                  </IconButton>
                </MenuButton>
                <Menu
                  variant="solid"
                  invertedColors
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "repeat(2, 70px)",
                    gridAutoRows: "70px",
                    gap: 1,
                    position: "absolute !important",
                    left: "-50px !important",
                    top: "-7px !important",
                    zIndex: "99999 !important",
                  }}
                  className="!p-4"
                >
                  <MenuItem
                    orientation="vertical"
                    onClick={() =>
                      navigate(
                        `${role === "admin" ? "/admin/profile" : "/profile"}`
                      )
                    }
                  >
                    <ListItemDecorator>
                      <Avatar>
                        <Person />
                      </Avatar>
                    </ListItemDecorator>
                    Hồ sơ
                  </MenuItem>
                  <MenuItem orientation="vertical" onClick={handleLogout}>
                    <ListItemDecorator>
                      <Avatar>
                        <LogoutOutlined />
                      </Avatar>
                    </ListItemDecorator>
                    Đăng xuất
                  </MenuItem>
                </Menu>
              </Dropdown>
            </Stack>
          </Stack>
        </Toolbar>
      </AppBar>

      <AppSidebar windowSize={windowSize} roll={roll} setRoll={setRoll} />
      <div style={{ width: "100%", marginTop: "110px", marginLeft: "16px" }}>
        {children}
      </div>
    </Box>
  );
};

export default AppLayout;
