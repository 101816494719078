import axios from ".";

export const uploadFile = (formData) =>
  axios.post("/uploads", formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });

const UploadServices = {
  uploadFile,
};

export default UploadServices;
