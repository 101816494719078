/* eslint-disable react/jsx-no-duplicate-props */
import { AttachFileOutlined, Close } from "@mui/icons-material";
import { Autocomplete, Grid, TextField, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import CustomerServices from "api/customer";
import OrderServices from "api/order";
import { ReactComponent as IconDownload } from "assets/Group.svg";
import ButtonCustom from "components/button";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { open } from "store/notificationSlice";
import UploadFile from "./UploadFile";
import { CONTACT_FORM, LOCATION, SHAPE, TYPE_SHAPE } from "constant";

const OrderNewForMember = () => {
  const [form, setForm] = useState({quantity: 1, shape: 'straight', typeShape: 'new', contactForm: 'email', phone: '', address: '', location: 'south'});
  const [listCustomer, setListCustomer] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChange = (e) => {
    let { name, value } = e.target;
    if (name === 'quantity') value = Number(value)
    setForm({ ...form, [name]: value });
  };

  const handleSubmit = async () => {
    try {
      await OrderServices.createOrderMember({
        ...form,
        note: undefined,
        customerId: Number(form.customerId),
      });
      dispatch(open({ isNoti: true, message: "Tạo đơn hàng thành công!" }));
      navigate("/order")
    } catch (error) {
      console.log(error)
      dispatch(
        open({
          isNoti: true,
          message: error.response?.data?.message,
        })
      );
    }
  };

  useEffect(() => {
    const fetchListCustomer = async () => {
      try {
        const response = await CustomerServices.getCustomerForCreator();
        setListCustomer(response.data.data);
      } catch (error) {
        setForm({ quantity: 1 });
        console.log(error);
      }
    };
    fetchListCustomer();
  }, []);

  useEffect(() => {
    const fetchCustomer = async () => {
      try {
        if (!form?.customerId) return
        const response = await CustomerServices.getCustomerByIdForCreator(
          form.customerId
        );
        setForm({
          ...form,
          note: response.data.note,
          customerName: response.data.name,
          phone: response.data.phone || '',
          address: response.data.address || '',
        });
      } catch (error) {
        setForm({ quantity: 1, address: '', phone: '' });
        console.log(error);
      }
    };
    fetchCustomer();
  }, [form?.customerId]);

  return (
    <Box className="boxShadow rounded-2xl" pb={3}>
      <Typography variant="h5" fontWeight="bold">
        Đơn hàng mới
      </Typography>
      <Box
        className="min-h-[80vh] rounded-2xl w-[99%] mt-3 md:px-8 md:border md:border-[#EBEBF0]"
        style={{
          boxShadow: "0px 8px 8px -4px #10182805",
        }}
      >
      <Grid container spacing={2} mt={1}>
          <Grid item xs={6}>
            <TextField
              label="Tên đơn hàng"
              className="w-full"
              name="orderName"
              multiline
              value={form?.orderName ? form?.orderName : ""}
              onChange={handleChange}
            />
        </Grid>
        <Grid item xs={6}>
              <Autocomplete
                name="location"
                defaultValue={{title: Object.values(LOCATION)[0], id: Object.keys(LOCATION)[0]}}
                onChange={(_, newValue) => {
                  handleChange({
                    target: { name: "location", value: newValue?.id },
                  });
                }}
                getOptionLabel={(option) => option?.title}
                options={Object.keys(LOCATION)?.map((k) => ({
                  title: `${LOCATION?.[k]}`,
                  id: k,
                }))}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Khu vực"
                    name="location"
                  />
                )}
          />
          </Grid>
      </Grid>
        <Grid container spacing={2} mt={2.5}>
          <Grid item xs={6}>
              <Autocomplete
                name="customerId"
                inputValue={form?.customerId}
                onChange={(_, newValue) => {
                  handleChange({
                    target: { name: "customerId", value: newValue?.id },
                  });
                }}
                getOptionLabel={(option) => option?.title}
                options={listCustomer?.map((item) => ({
                  title: `${item?.code}`,
                  id: item?.id,
                }))}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Mã khách hàng"
                    name="customerId"
                  />
                )}
          />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Lưu ý khách hàng"
              className="w-full"
              name="note"
              value={form?.note || ''}
              aria-readonly={true}
              multiline
              inputProps={{}}
            />
        </Grid>
      </Grid>

        <Grid container spacing={2} mt={1}>
          <Grid item xs={6}>
              <Autocomplete
                name="shape"
                defaultValue={{title: Object.values(SHAPE)[0], id: Object.keys(SHAPE)[0]}}
                onChange={(_, newValue) => {
                  handleChange({
                    target: { name: "shape", value: newValue?.id },
                  });
                }}
                getOptionLabel={(option) => option?.title}
                options={Object.keys(SHAPE)?.map((k) => ({
                  title: `${SHAPE?.[k]}`,
                  id: k,
                }))}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Chọn khuôn"
                    name="shape"
                  />
                )}
          />
          </Grid>
          <Grid item xs={6}>
              <Autocomplete
                name="typeShape"
                defaultValue={{title: Object.values(TYPE_SHAPE)[0], id: Object.keys(TYPE_SHAPE)[0]}}
                onChange={(_, newValue) => {
                  handleChange({
                    target: { name: "typeShape", value: newValue?.id },
                  });
                }}
                getOptionLabel={(option) => option?.title}
                options={Object.keys(TYPE_SHAPE)?.map((k) => ({
                  title: `${TYPE_SHAPE?.[k]}`,
                  id: k,
                }))}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Loại khuôn"
                    name="typeShape"
                  />
                )}
          />
          </Grid>
          
        </Grid>

        <Grid container spacing={2} mt={1}>
          <Grid item xs={6}>
                <Autocomplete
                  name="contactForm"
                  defaultValue={{title: Object.values(CONTACT_FORM)[0], id: Object.keys(CONTACT_FORM)[0]}}
                  onChange={(_, newValue) => {
                    handleChange({
                      target: { name: "contactForm", value: newValue?.id },
                    });
                  }}
                  getOptionLabel={(option) => option?.title}
                  options={Object.keys(CONTACT_FORM)?.map((k) => ({
                    title: `${CONTACT_FORM?.[k]}`,
                    id: k,
                  }))}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Hình thức nhận khuôn"
                      name="contactForm"
                    />
                  )}
            />
            </Grid>
          <Grid item xs={6}>
              <TextField
                label="Số lượng"
                className="w-full"
                name="quantity"
                type="number"
                value={form?.quantity ? Number(form?.quantity) : 1}
                onChange={handleChange}
              />
            </Grid>
        </Grid>

        <Grid container spacing={2} mt={1}>
          <Grid item xs={12}>
            <TextField
              label="Mô tả"
              className="w-full"
              name="items"
              value={form?.items}
              onChange={handleChange}
              multiline
              inputProps={{
                style: {
                  height: "80px",
                },
              }}
            />
          </Grid>
        </Grid>

        <Grid container spacing={5} className="pl-2 pt-2">
          <Grid item xs={12}>
            <div className="flex flex-col gap-2 mt-4 -ml-2">
              {form?.files?.map((item) => (
                <div
                  key={item}
                  className="flex gap-1 cursor-pointer border rounded-xl p-1"
                >
                  <span>
                    <AttachFileOutlined fontSize="small" />
                  </span>
                  <a
                    href={item}
                    target="_blank"
                    className="text-gray-500 w-full truncate"
                    rel="noreferrer"
                  >
                    {item.replace('public/', '')}
                  </a>
                  <span
                    onClick={() =>
                      setForm({
                        ...form,
                        files: form?.files?.filter((i) => i !== item),
                      })
                    }
                  >
                    <Close size="small" />
                  </span>
                </div>
              ))}
            </div>
          </Grid>
        </Grid>

        <Grid container mt={2}>
          <Grid item xs={12} className="relative w-full h-[140px]">
            <UploadFile
              value={form?.files}
              onChange={(value) =>
                setForm((prev) => ({
                  ...prev,
                  files: value,
                }))
              }
              imageAspect="1/1"
              folder="files"
              classes={
                "absolute top-0 -z-50 w-full h-full cursor-pointer duration-200 hover:bg-[#dfdddd5a] border rounded-xl"
              }
              helperText={
                <div className="w-full flex flex-col justify-center items-center h-full border-gradient-primary border-gradient-rounded">
                  <span>
                    <IconDownload />
                  </span>
                  <span className="text-xl">Tải hình ảnh, files</span>
                </div>
              }
            />
          </Grid>
        </Grid>

        <Grid container spacing={0} mt={6} className="w-full justify-end">
          <ButtonCustom
            size="medium"
            classes="px-12 py-3"
            onClick={handleSubmit}
          >
            Tạo đơn
          </ButtonCustom>
        </Grid>
      </Box>
    </Box>
  );
};

export default OrderNewForMember;
