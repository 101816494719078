import { Box, Grid, Typography } from "@mui/material";
import { ReactComponent as TrashPink } from "assets/trashPink.svg";
import ButtonCustom from "components/button";

const Delete = ({ handleClose, formDelete, handleDeleteUser }) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 450,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: 4,
    p: 4,
  };

  return (
    <Box
      p={3}
      sx={style}
      className="boxShadow text-center w-[97%] md:w-[450px] p-2 md:p-4"
    >
      <div className="w-full flex justify-center mb-4">
        <div className="rounded-full w-[100px] h-[100px] bg-[#f45b691a] flex items-center justify-center">
          <div className="rounded-full w-[76px] h-[76px] bg-[#f45b690d] flex items-center justify-center">
            <TrashPink />
          </div>
        </div>
      </div>
      <Typography variant="h5" mb={3} fontWeight={700}>
        Bạn có chắc chắn muốn xoá {formDelete?.name}?
      </Typography>

      <Typography variant="p" mb={3} fontWeight={500}>
        {formDelete?.name} sẽ bị xoá khỏi hệ thống
      </Typography>

      <Grid container className="mt-6 w-full flex gap-2 justify-between mt-4">
        <ButtonCustom
          classes="w-[48%] py-3 !text-black"
          variant="none"
          onClick={handleClose}
        >
          Huỷ bỏ
        </ButtonCustom>
        <ButtonCustom
          classes="w-[48%] py-3"
          variant="primary"
          onClick={handleDeleteUser}
        >
          Xoá
        </ButtonCustom>
      </Grid>
    </Box>
  );
};

export default Delete;
