import { createSlice } from "@reduxjs/toolkit";
import { removeLocalStore } from "utils/storage";
import { USER_INFO } from "constant";

const initialState = {
  isLogin: false,
  user: {},
  role: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginReducer: (state, actions) => {
      state.isLogin = actions.payload.isLogin;
      state.user = actions.payload.user;
      state.role = actions.payload.role;
    },
    logout: (state) => {
      state.isLogin = false;
      state.user = {};
      state.role = null;
      removeLocalStore(USER_INFO);
    },
  },
});

export const { loginReducer, logout } = authSlice.actions;
export const getAuth = (state) => state.auth;
export default authSlice.reducer;
