import React from "react";
import AppRouter from "./routes";
function App() {
  return (
    <div className="relative min-h-screen overflow-hidden">
      <AppRouter />
    </div>
  );
}

export default App;
