import axios from "axios";

import { BASE_URL, USER_INFO } from "../constant";
import { getUserStorage, removeLocalStore } from "../utils/storage";

const instance = axios.create({
  baseURL: BASE_URL,
});

export const getToken = () => {
  try {
    const cache = getUserStorage(USER_INFO);
    if (cache && typeof cache === "object") {
      return cache.token.accessToken;
    }
  } catch (error) {
    console.error(error);
  }
  removeLocalStore(USER_INFO);
  return undefined;
};

instance.interceptors.request.use((config) => {
  const token = getToken();
  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }
  return config;
});

instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const status = error.response ? error.response.status : null;
    if (status === 401) {
      removeLocalStore(USER_INFO);
      window.location.href = "/"; 
      return Promise.reject(error);
    }
    return Promise.reject(error);
  }
);

export default instance;
