/* eslint-disable react/jsx-no-duplicate-props */
import {
  AttachFileOutlined,
  Check,
  Close,
  VisibilityOutlined,
} from "@mui/icons-material";
import {
  Autocomplete,
  FormControlLabel,
  Grid,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import OrderServices, { updateProcess } from "api/order";
import { ReactComponent as IconEdit } from "assets/message-edit.svg";
import ButtonCustom from "components/button";
import { CONTACT_FORM, LOCATION, PROCESS, SHAPE, TYPE_SHAPE, VietnameseRoles } from "constant";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { open } from "store/notificationSlice";
import ModalDetail from "./modalDetail";
import ModalEdit from "./modalEdit";
import { getSearchUsers } from "api/user";

const OrderDetail = () => {
  const [form, setForm] = useState({
    code: "",
    orderStatus: "",
    items: "",
    description: "",
    orderName: '',
    shape: 'straight',
    typeShape: 'new',
    contactForm: 'email',
    quantity: 1,
    location: 'south'
  });
  const [process, setProcess] = useState({});
  const [users, setListUser] = useState([]);
  const [assignees, setAssignees] = useState({});
  const { id } = useParams();
  const { role } = useSelector((state) => state.auth);
  const [modalDetail, setModalDetail] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [dataDetail, setDataDetail] = useState({});
  const dispatch = useDispatch();

  const handleUpdate = async () => {
    try {
      await OrderServices.updateOrder(id, {
        ...form,
      });
      dispatch(
        open({ isNoti: true, message: "Cập nhật đơn hàng thành công!" })
      );
    } catch (error) {
      console.log(error);
      dispatch(open({ isNoti: true, message: error.response?.data?.message }));
    }
  };

  const openModalDetail = (data) => {
    setDataDetail(data);
    setModalDetail(!modalDetail);
  };

  const openModalEdit = (data) => {
    setDataDetail(data);
    setModalEdit(!modalEdit);
  };

  const handleChangeForm = (e) => {
    let { name, value } = e.target;
    if (name === 'quantity') value = Number(value)
    setForm({ ...form, [name]: value });
  };

  const renderEdit = (data) => {
    if (role === "admin") {
      return (
        <div
          className="absolute z-50 top-11 right-0 p-2"
          onClick={() => openModalEdit(data)}
        >
          <span>
            <IconEdit />
          </span>
        </div>
      );
    } else {
      if (role === "designer") {
        return (
          <div
            className="absolute z-50 top-11 right-0 p-2"
            onClick={() => openModalEdit(data)}
          >
            <span>
              <IconEdit />
            </span>
          </div>
        );
      } else {
        return (
          <div
            className="absolute z-50 top-11 right-0 p-2"
            onClick={() => openModalDetail(data)}
          >
            <span>
              <VisibilityOutlined sx={{ color: "#000" }} />
            </span>
          </div>
        );
      }
    }
  };
  const onChangeAssignee = (e, key) => {
    const role = process[key] || {}
    const newProcess = {...process, [key]: {...role, notice: e.target.value || ''} } 
    setProcess(newProcess)
  }
  // const handleChangeAssignee = async (e, name) => {
  //   await updateProcess(id, { type: name, notice: process[name].notice })
  // };

  const handleChangeAssignee = async (e) => {
    let { name, value } = e.target;
    const newUser = users?.find(u => u?.id === Number(value))
    setProcess({...process, [name]: {user: newUser ?? null }})
    await updateProcess(id, { type: name, userId: Number(value) > 0 ? Number(value) : null })
    setAssignees({ ...assignees, [name]: value ?? null });
  };

  const handleConfirm = (isCheck) => {
    // eslint-disable-next-line no-use-before-define
    let confirm = window.confirm(
      isCheck ? "Bạn đã hoàn thành công việc!" : "Bạn muốn huỷ bỏ hoàn thành!"
    );
    return confirm;
  };

  const handleChangeProcess = async (key, value) => {
    const check = handleConfirm(value);
    if (!check) return;
    try {
      const payload = {
        tick: value,
        type: key,
      };
      await OrderServices.updateProcess(id, payload);
      const message = value
        ? `${VietnameseRoles[key]} đã hoàn thành công việc!`
        : `${VietnameseRoles[key]} đang tiếp tục công việc!`;
      dispatch(
        open({
          isNoti: true,
          message: message,
        })
      );
    } catch (error) {
      console.log(error);
      dispatch(
        open({
          isNoti: true,
          message: error.response?.data?.message,
        })
      );
    }
    setProcess({ ...process, [key]: { ...process[key], tick: value } });
  };

  const fetchOrderDetail = async () => {
    try {
      const response = await OrderServices.getOrderById(id);
      setForm({
        ...form,
        code: response?.data.code,
        orderStatus: response?.data.orderStatus,
        items: response?.data.items,
        description: response?.data.description,
        customer: response?.data?.customer,
        creator: response?.data?.creator,
        orderName: response?.data?.orderName || '',
        shape: response?.data?.shape || '',
        typeShape: response?.data?.typeShape || '',
        contactForm: response?.data?.contactForm || '',
        quantity: Number(response?.data?.quantity),
        location: response?.data?.location
      });
      const progressOrders = response.data.progressOrders
      setProcess(progressOrders);
    } catch (error) {
      console.log(error);
    }
  }

  const fetchUserList = async () => {
    try {
      const response = await getSearchUsers()
      if (response?.data?.data) { 
        setListUser(response.data.data)
      }
    } catch (error) {
      console.log(error);
      alert('Could not fetch list user')
    }
  }

  useEffect(() => {
    fetchUserList()
  }, [])


  useEffect(() => {
    if (!id) return;
    fetchOrderDetail();
  }, [id]);
  return (
    <React.Fragment>
      <Box className="boxShadow rounded-2xl my-3" pb={3}>
        <Typography variant="h5" fontWeight="bold">
          Chi tiết đơn hàng
        </Typography>
        <Stack p={2} pr={3} className="flex gap-3 my-3 mb-8">
          <Grid container spacing={{ xs: 2, md: 8 }}>
            <Grid item xs={4}>
              <TextField
                className="w-full h-[60px]"
                id="standard-basic"
                label="Mã đơn hàng"
                variant="standard"
                value={form?.code}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                className="w-full h-[60px]"
                id="standard-basic"
                label="Mã khách hàng"
                variant="standard"
                value={form?.customer?.code || ""}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                className="w-full h-[60px]"
                style={{ height: '100%' }}
                id="standard-basic"
                label="Lưu ý khách hàng"
                variant="standard"
                multiline
                value={form?.customer?.note || ""}
              />
            </Grid>
          </Grid>
          <Grid container spacing={{ xs: 2, md: 8 }}>
            <Grid item xs={4}>
              <TextField
                className="w-full h-[60px]"
                id="standard-basic"
                label="Chọn khuôn"
                variant="standard"
                value={SHAPE[form?.shape]}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                className="w-full h-[60px]"
                id="standard-basic"
                label="Loại khuôn"
                variant="standard"
                value={TYPE_SHAPE[form?.typeShape] || ""}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                className="w-full h-[60px]"
                id="standard-basic"
                label="Hình thức nhận khuôn"
                variant="standard"
                value={CONTACT_FORM[form?.contactForm] || ""}
              />
            </Grid>
          </Grid>
          <Grid container spacing={{ xs: 2, md: 8 }}>
            <Grid item xs={4}>
              <TextField
                style={{ height: '100%' }}
                className="w-full h-[60px]"
                id="standard-basic"
                label="Tên đơn hàng"
                variant="standard"
                value={form?.orderName}
                multiline
                name="orderName"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                className="w-full h-[60px]"
                id="standard-basic"
                label="Khu vực"
                InputProps={{ readOnly: true }}
                variant="standard"
                value={LOCATION[form?.location]}
                onChange={handleChangeForm}
                name="location"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                className="w-full h-[60px]"
                id="standard-basic"
                label="Số lượng"
                variant="standard"
                value={Number(form?.quantity)}
                onChange={handleChangeForm}
                name="quantity"
              />
            </Grid>
          </Grid>
          <Grid container spacing={{ xs: 2, md: 8 }}>
            <Grid item xs={6}>
              <TextField
                className="w-full h-[60px]"
                id="standard-basic"
                label="Ngày tạo"
                variant="standard"
                value={moment(form?.createdAt).format("DD/MM/YYYY")}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                className="w-full h-[60px]"
                id="standard-basic"
                label="Thời gian"
                variant="standard"
                value={moment(form?.createdAt).format("LT")}
              />
            </Grid>
          </Grid>
          <Grid container spacing={8}>
            <Grid item xs={6}>
              <TextField
                className="w-full h-[60px]"
                id="standard-basic"
                label="Người tạo đơn"
                variant="standard"
                value={form?.creator?.name || ""}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                className="w-full h-[60px]"
                id="standard-basic"
                label="Trạng thái"
                variant="standard"
                value={PROCESS[form?.status || "new"]}
              />
            </Grid>
          </Grid>
          <Grid container spacing={8}>
            <Grid item xs={12}>
              <Grid item xs={12}>
                <TextField
                  className="w-full h-[60px]"
                  id="standard-basic"
                  label="Mô tả chi tiết"
                  value={form?.items}
                  name="items"
                  onChange={handleChangeForm}
                  multiline
                  inputProps={{
                    style: {
                      height: "80px",
                    },
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={5} className="pl-2 pt-2">
            <Grid item xs={12}>
              <div className="flex flex-col gap-2 mt-4 -ml-2">
                {form?.files?.map((item) => (
                  <div
                    key={item}
                    className="flex gap-1 cursor-pointer border rounded-xl p-1"
                  >
                    <span>
                      <AttachFileOutlined fontSize="small" />
                    </span>
                    <a
                      href={item}
                      target="_blank"
                      className="text-gray-500 w-full truncate"
                      rel="noreferrer"
                    >
                      {item}
                    </a>
                    <span
                      onClick={() =>
                        setForm({
                          ...form,
                          files: form?.files?.filter((i) => i !== item),
                        })
                      }
                    >
                      <Close size="small" />
                    </span>
                  </div>
                ))}
              </div>
            </Grid>
          </Grid>
        </Stack>
      </Box>

      <Box className="boxShadow rounded-2xl" pb={3}>
        <Typography variant="h5" fontWeight="bold">
          Theo dõi tiến độ
        </Typography>

        <Grid
          container
          spacing={5}
          px={4}
          mt={1}
          className="border-b border-gray-200 my-8 pb-8"
        >
          <Grid item md={4} xs={12} className="relative">
            <FormControlLabel
              className="relative w-full !ml-0 flex gap-4 cursor-pointer !z-50"
              onClick={() =>
                handleChangeProcess("creator", !process?.creator?.tick)
              }
              control={
                <div
                  className={`h-[42px] w-[42px] rounded-[8px] flex justify-center items-center ${
                    process.creator?.tick
                      ? "bg-gradient-primary"
                      : "border-gradient-primary border-gradient-rounded"
                  }`}
                >
                  {process?.creator?.tick && <Check sx={{ color: "#fff" }} />}
                </div>
              }
              label={
                <div className="flex items-center">
                  <div className="flex flex-col">
                    <span className="font-bold">1. Người tạo đơn</span>
                    <span>{process?.creator?.user?.name}</span>
                  </div>
                </div>
              }
            />
            <div className="mt-4">
              <Grid item xs={12}>
                <Autocomplete
                      name="creator"
                      value={{
                        title: process?.creator?.user?.name || '',
                        id: process?.creator?.user?.id || null
                      }}
                      onChange={(_, newValue) => {
                        handleChangeAssignee({
                          target: { name: "creator", value: newValue?.id },
                        });
                      }}
                      getOptionLabel={(option) => option?.title}
                      options={users?.filter(u => u.role === 'creator').map(user => ({
                        title: user?.name,
                        id: user?.id,
                      }))}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={ process?.creator?.user?.id ? 'Đổi người thực hiện' : 'Người thực hiện' }
                          name="creator"
                        />
                      )}
                />
              </Grid>
            </div>
            {renderEdit(process?.creator)}
          </Grid>
          <Grid item md={4} xs={12} className="relative">
            <FormControlLabel
              className="w-full relative flex gap-4 !ml-0 cursor-pointer !z-50"
              control={
                <div
                  onClick={() =>
                    handleChangeProcess("designer", !process?.designer?.tick)
                  }
                  className={`h-[42px] w-[42px] rounded-[8px] flex justify-center items-center ${
                    process.designer?.tick
                      ? "bg-gradient-primary"
                      : "border-gradient-primary border-gradient-rounded"
                  }`}
                >
                  {process?.designer?.tick && <Check sx={{ color: "#fff" }} />}
                </div>
              }
              label={
                <div className="flex items-center">
                  <div
                    className="flex flex-col"
                    onClick={() =>
                      handleChangeProcess("designer", !process?.designer?.tick)
                    }
                  >
                    <span className="font-bold">2. Thiết kế</span>
                    <span>{process?.designer?.user?.name}</span>
                  </div>
                </div>
              }
            />
            <div className="mt-4">
              <Grid item xs={12}>
                <Autocomplete
                      name="designer"
                      value={{
                        title: process?.designer?.user?.name || '',
                        id: process?.designer?.user?.id || null
                      }}
                      onChange={(_, newValue) => {
                        handleChangeAssignee({
                          target: { name: "designer", value: newValue?.id },
                        });
                      }}
                      getOptionLabel={(option) => option?.title}
                      options={users?.filter(u => u.role === 'designer').map(user => ({
                        title: user?.name,
                        id: user?.id,
                      }))}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={ process?.designer?.user?.id ? 'Đổi người thực hiện' : 'Người thực hiện' }
                          name="designer"
                        />
                      )}
                />
              </Grid>
            </div>

            {renderEdit(process?.designer)}
          </Grid>
          <Grid item md={4} xs={12} className="relative">
            <FormControlLabel
              className="relative flex gap-4 !ml-0 cursor-pointer !z-50"
              onClick={() =>
                handleChangeProcess("lazerWorker", !process?.lazerWorker?.tick)
              }
              control={
                <div
                  className={`h-[42px] w-[42px] rounded-[8px] flex justify-center items-center ${
                    process?.lazerWorker?.tick
                      ? "bg-gradient-primary"
                      : "border-gradient-primary border-gradient-rounded"
                  }`}
                >
                  {process?.lazerWorker?.tick && (
                    <Check sx={{ color: "#fff" }} />
                  )}
                </div>
              }
              label={
                <div className="flex flex-col">
                  <span className="font-bold">3. Thợ lazer</span>
                  <span>{process?.lazerWorker?.user?.name}</span>
                </div>
              }
            />
            <div className="mt-4">
            <Grid item xs={12}>
                <Autocomplete
                      name="lazerWorker"
                      value={{
                        title: process?.lazerWorker?.user?.name || '',
                        id: process?.lazerWorker?.user?.id || null
                      }}
                      onChange={(_, newValue) => {
                        handleChangeAssignee({
                          target: { name: "lazerWorker", value: newValue?.id },
                        });
                      }}
                      getOptionLabel={(option) => option?.title}
                      options={users?.filter(u => u.role === 'lazerWorker').map(user => ({
                        title: user?.name,
                        id: user?.id,
                      }))}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={ process?.lazerWorker?.user?.id ? 'Đổi người thực hiện' : 'Người thực hiện' }
                          name="lazerWorker"
                        />
                      )}
                />
              </Grid>
            </div>
            {renderEdit(process?.lazerWorker)}
          </Grid>
        </Grid>

        <Grid
          container
          spacing={5}
          px={4}
          className="border-b border-gray-200 my-8 pb-8"
        >
          <Grid item md={4} xs={12} className="relative">
            <FormControlLabel
              className="w-full relative flex !ml-0 gap-4 cursor-pointer"
              control={
                <div
                  onClick={() =>
                    handleChangeProcess(
                      "machineBendingWoker",
                      !process?.machineBendingWoker?.tick
                    )
                  }
                  className={`relatvie h-[42px] w-[42px] rounded-[8px] flex justify-center items-center ${
                    process?.machineBendingWoker?.tick
                      ? "bg-gradient-primary"
                      : "border-gradient-primary border-gradient-rounded"
                  }`}
                >
                  {process?.machineBendingWoker?.tick && (
                    <Check sx={{ color: "#fff" }} />
                  )}
                </div>
              }
              label={
                <div className="flex justify-between items-center">
                  <div
                    className="flex flex-col"
                    onClick={() =>
                      handleChangeProcess(
                        "machineBendingWoker",
                        !process?.machineBendingWoker?.tick
                      )
                    }
                  >
                    <span className="font-bold">4. Thợ uốn máy</span>
                    <span>{process?.machineBendingWoker?.user?.name}</span>
                  </div>
                </div>
              }
            />
            <div className="mt-4">
              <Grid item xs={12}>
                  <Autocomplete
                        name="machineBendingWoker"
                        value={{
                          title: process?.machineBendingWoker?.user?.name || '',
                          id: process?.machineBendingWoker?.user?.id || null
                        }}
                        onChange={(_, newValue) => {
                          handleChangeAssignee({
                            target: { name: "machineBendingWoker", value: newValue?.id },
                          });
                        }}
                        getOptionLabel={(option) => option?.title}
                        options={users?.filter(u => u.role === 'machineBendingWoker').map(user => ({
                          title: user?.name,
                          id: user?.id,
                        }))}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={ process?.machineBendingWoker?.user?.id ? 'Đổi người thực hiện' : 'Người thực hiện' }
                            name="machineBendingWoker"
                          />
                        )}
                  />
              </Grid>
            </div>
            {renderEdit(process?.machineBendingWoker)}
          </Grid>
          <Grid item md={4} xs={12} className="relative">
            <FormControlLabel
              className="relative flex gap-4 !ml-0 cursor-pointer !z-50"
              onClick={() =>
                handleChangeProcess(
                  "handBendingWorker",
                  !process?.handBendingWorker?.tick
                )
              }
              control={
                <div
                  className={`h-[42px] w-[42px] rounded-[8px] flex justify-center items-center ${
                    process?.handBendingWorker?.tick
                      ? "bg-gradient-primary"
                      : "border-gradient-primary border-gradient-rounded"
                  }`}
                >
                  {process?.handBendingWorker?.tick && (
                    <Check sx={{ color: "#fff" }} />
                  )}
                </div>
              }
              label={
                <div className="flex flex-col">
                  <span className="font-bold">5. Thợ thủ công</span>
                  <span>{process?.handBendingWorker?.user?.name}</span>
                </div>
              }
            />
            <div className="mt-4">
              <Grid item xs={12}>
                    <Autocomplete
                          name="handBendingWorker"
                          value={{
                            title: process?.handBendingWorker?.user?.name || '',
                            id: process?.handBendingWorker?.user?.id || null
                          }}
                          onChange={(_, newValue) => {
                            handleChangeAssignee({
                              target: { name: "handBendingWorker", value: newValue?.id },
                            });
                          }}
                          getOptionLabel={(option) => option?.title}
                          options={users?.filter(u => u.role === 'handBendingWorker').map(user => ({
                            title: user?.name,
                            id: user?.id,
                          }))}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={ process?.handBendingWorker?.user?.id ? 'Đổi người thực hiện' : 'Người thực hiện' }
                              name="handBendingWorker"
                            />
                          )}
                    />
                </Grid>
            </div>
            {renderEdit(process?.handBendingWorker)}
          </Grid>
          <Grid item md={4} xs={12} className="relative">
            <FormControlLabel
              className="relative flex gap-4 !ml-0 cursor-pointer !z-50"
              onClick={() => handleChangeProcess("qc", !process?.qc?.tick)}
              control={
                <div
                  className={`h-[42px] w-[42px] rounded-[8px] flex justify-center items-center ${
                    process?.qc?.tick
                      ? "bg-gradient-primary"
                      : "border-gradient-primary border-gradient-rounded"
                  }`}
                >
                  {process?.qc?.tick && <Check sx={{ color: "#fff" }} />}
                </div>
              }
              label={
                <div className="flex flex-col">
                  <span className="font-bold">6. QC</span>
                  <span>{process?.qc?.user?.name}</span>
                </div>
              }
            />
            <div className="mt-4">
              <Grid item xs={12}>
                    <Autocomplete
                          name="qc"
                          value={{
                            title: process?.qc?.user?.name || '',
                            id: process?.qc?.user?.id || null
                          }}
                          onChange={(_, newValue) => {
                            handleChangeAssignee({
                              target: { name: "qc", value: newValue?.id },
                            });
                          }}
                          getOptionLabel={(option) => option?.title}
                          options={users?.filter(u => u.role === 'qc').map(user => ({
                            title: user?.name,
                            id: user?.id,
                          }))}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={ process?.qc?.user?.id ? 'Đổi người thực hiện' : 'Người thực hiện' }
                              name="qc"
                            />
                          )}
                    />
              </Grid>
            </div>
            {renderEdit(process?.qc)}
          </Grid>
        </Grid>

        <Grid
          container
          spacing={5}
          px={4}
          className="border-b border-gray-200 my-8 pb-8"
        >
          <Grid item md={4} xs={12} className="relative">
            <FormControlLabel
              className="relative flex gap-4 !ml-0 cursor-pointer !z-50"
              onClick={() =>
                handleChangeProcess("shipper", !process?.shipper?.tick)
              }
              control={
                <div
                  className={`h-[42px] w-[42px] rounded-[8px] flex justify-center items-center ${
                    process?.shipper?.tick
                      ? "bg-gradient-primary"
                      : "border-gradient-primary border-gradient-rounded"
                  }`}
                >
                  {process?.shipper?.tick && <Check sx={{ color: "#fff" }} />}
                </div>
              }
              label={
                <div className="flex flex-col">
                  <span className="font-bold">7. Giao hàng</span>
                  <span>{process?.shipper?.user?.name}</span>
                </div>
              }
            />
            <div className="mt-4">
            <Grid item xs={12}>
                    <Autocomplete
                          name="shipper"
                          value={{
                            title: process?.shipper?.user?.name || '',
                            id: process?.shipper?.user?.id || null
                          }}
                          onChange={(_, newValue) => {
                            handleChangeAssignee({
                              target: { name: "shipper", value: newValue?.id },
                            });
                          }}
                          getOptionLabel={(option) => option?.title}
                          options={users?.filter(u => u.role === 'shipper').map(user => ({
                            title: user?.name,
                            id: user?.id,
                          }))}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={ process?.shipper?.user?.id ? 'Đổi người thực hiện' : 'Người thực hiện' }
                              name="shipper"
                            />
                          )}
                    />
              </Grid>
            </div>
            {renderEdit(process?.shipper)}
          </Grid>
        </Grid>

        <Grid container spacing={8} mt={2} className="!flex !items-center">
          <Grid item xs={6} className="!pl-20"></Grid>
          <Grid item xs={6} className="w-full flex items-end justify-end !pr-4">
            <ButtonCustom
              size="medium"
              ßß
              classes="px-8 py-3"
              onClick={handleUpdate}
            >
              Lưu thay đổi
            </ButtonCustom>
          </Grid>
        </Grid>
      </Box>

      <Modal open={modalDetail} onClose={openModalDetail}>
        <ModalDetail form={dataDetail} handleClose={openModalDetail} />
      </Modal>

      <Modal open={modalEdit} onClose={openModalEdit}>
        <ModalEdit
          data={dataDetail}
          handleClose={openModalEdit}
          fetchOrderDetail={fetchOrderDetail}
        />
      </Modal>
    </React.Fragment>
  );
};

export default OrderDetail;
