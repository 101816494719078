import { Box, TextField, Typography } from "@mui/material";
import AuthServices from "api/auth";
import ButtonCustom from "components/button";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { open } from "store/notificationSlice";
import { ReactComponent as ProfileIcon } from "assets/profile-circle.svg";
import UploadImage from "./UploadImage";

const Profile = () => {
  const [data, setData] = useState({});
  const [formPass, setFormPass] = useState({});
  const [form, setForm] = useState({});

  const dispatch = useDispatch();

  const fetchDataUser = async () => {
    try {
      const response = await AuthServices.getMe();
      setData(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setFormPass((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const updatePassword = async () => {
    try {
      if (!formPass?.password || !formPass.confirmPassword) {
        dispatch(
          open({
            isNoti: true,
            message: "Please Enter input your new password",
          })
        );
        return;
      }
      if (formPass?.password === formPass.confirmPassword) {
        await AuthServices.updateMe({
          password: formPass.password,
        });
        dispatch(
          open({
            isNoti: true,
            message: "Password update successful",
          })
        );
      } else {
        dispatch(
          open({
            isNoti: true,
            message: "Password does not match, please re-enter",
          })
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchDataUser();
  }, [form?.images]);

  return (
    <Box className="boxShadow rounded-2xl" pb={3}>
      <Typography variant="h5" fontWeight="bold">
        Tài khoản
      </Typography>
      <div className="p-2 mt-4">
        <div className="mb-4">
          <div className="group mb-6 w-[184px] h-[173px] border-[#FF0C4E] mb-2 relative border-2 border-dashed rounded-3xl">
            <div className="absolute top-0 left-0 right-0 w-full h-full flex items-center justify-center flex-col opacity-0 group-hover:opacity-100">
              <ProfileIcon />
              <p className="font-medium text-transparent text-base bg-clip-text bg-gradient-to-r from-red-700 to-purple-900 mt-2">
                Upload avatar
              </p>
            </div>
            <img
              src={data?.avatar}
              alt=""
              className="absolute top-0 left-0 right-0 opacity-100 w-full rounded-3xl h-full group-hover:opacity-50"
            />
            <UploadImage
              value={form?.images}
              classes="w-full"
              onChange={(value) =>
                setForm((prev) => ({
                  ...prev,
                  images: [...(form.images || []), value],
                }))
              }
              imageAspect="1/1"
            />
          </div>
          <Typography className="text-xl" variant="p" fontWeight="bold">
            {data?.name}
          </Typography>
        </div>

        <div className="py-4 md:border-y flex flex-wrap">
          <div className="w-full md:w-1/3 border-b md:mt-0 mt-3 md:border-none">
            <div className="text-base font-medium text-[#A7A19E]">
              Họ và tên
            </div>
            <div className="font-medium text-xl">{data?.name}</div>
          </div>

          <div className="w-full md:w-1/3 border-b md:mt-0 mt-3 md:border-none">
            <div className="text-base font-medium text-[#A7A19E]">email</div>
            <div className="font-medium text-xl">{data?.email}</div>
          </div>

          <div className="w-full md:w-1/3 border-b md:mt-0 mt-3 md:border-none">
            <div className="text-base font-medium text-[#A7A19E]">
              Phân quyền
            </div>
            <div className="font-medium text-xl">{data?.role}</div>
          </div>
        </div>

        <div className="mt-4">
          <Typography className="text-xl" variant="p" fontWeight="bold">
            Change Password
          </Typography>

          <div className="flex flex-wrap gap-2 mt-4">
            <TextField
              label="Mật khẩu"
              variant="outlined"
              type="password"
              name="password"
              className="w-full md:!mt-0 !mt-3 md:w-1/3"
              defaultValue="password"
              onChange={handleChangeInput}
              value={formPass?.password}
            />
            <TextField
              label="Nhập lại mật khẩu"
              variant="outlined"
              type="password"
              name="confirmPassword"
              className="w-full md:!mt-0 !mt-3 md:w-1/3"
              defaultValue="password"
              onChange={handleChangeInput}
              value={formPass?.confirmPassword}
            />
          </div>
        </div>

        <ButtonCustom
          onClick={() => updatePassword()}
          classes="w-[166px] h-[50px] text-base mt-4"
          size="small"
        >
          Update Password
        </ButtonCustom>
      </div>
    </Box>
  );
};

export default Profile;
