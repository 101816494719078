import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import AuthServices from "api/auth";
import { USER_INFO } from "constant";
import { loginReducer } from "store/authSlice";
import { setUserStorage } from "utils/storage";
import { Autocomplete, Box, Checkbox, FormControlLabel, TextField } from "@mui/material";
import ButtonCustom from "components/button";
import { ReactComponent as PassIcon } from "assets/passIcon.svg";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { open } from "store/notificationSlice";
import { useEffect, useState } from "react";
import { getSearchUsers } from "api/user";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [users, setListUsers] = useState([]);

  const [username, setUsername] = useState('');

  const [visibility, setVisibility] = useState(false)

  const fetchUserList = async () => {
    try {
      const response = await getSearchUsers()
      if (response?.data?.data) { 
        setListUsers(response.data.data)
      }
    } catch (error) {
      console.log(error);
      alert('Could not fetch list user')
    }
  }

  useEffect(() => {
    fetchUserList()
  }, [])


  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const payload = {
      username: data.get("name"),
      password: data.get("password"),
    };
    try {
      const response = await AuthServices.login(payload);
      const { accessToken, refreshToken, user } = response?.data;
      if (user) {
        dispatch(open({ isNoti: true, message: "Đăng nhập thành công!" }));
        dispatch(loginReducer({ isLogin: true, user: user, role: user.role }));
        const token = { accessToken, refreshToken };
        setUserStorage(USER_INFO, { user, token });

        const timeout = setTimeout(() => {
          if (user.role === "admin") {
            navigate(`/admin/order`);
          } else {
            navigate(`/order`);
          }
        }, 1000);

        return () => clearTimeout(timeout);
      }
    } catch (error) {
      dispatch(open({ isNoti: true, message: error.response?.data?.message }));
    }
  };

  return (
    <div className="h-screen w-full flex flex-col justify-center items-center">
      <div className="flex w-full h-full">
        <div className="flex justify-end items-center w-full md:w-[45%]">
          <div className="px-8 md:pl-12 xl:pl-[200px] w-full">
            <div className="text-[130px] font-bold w-[80%] mx-auto">
              <img src="assets/logoMain.png" alt="" className="mx-auto" />
            </div>
            <div>
              <Box
                component="form"
                onSubmit={handleSubmit}
                noValidate
                sx={{ mt: 1 }}
              >
                <div className="w-full relative mb-4">
                <Autocomplete
                    name="name"
                    onChange={(_, newValue) => {
                      setUsername(newValue)
                    }}
                    getOptionLabel={(option) => option?.title}
                    options={users?.map((item) => ({
                      title: item.username,
                      id: item?.id,
                    }))}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Tên đăng nhập"
                        name="name"
                      />
                    )}
                  />
                </div>

                <div className="w-full relative mb-4">
                  <input
                    name="password"
                    placeholder="MẬT KHẨU"
                    type={visibility ? '' : 'password'}
                    id="password"
                    className="w-full pl-14 pr-4 py-6 bg-[#F5F5F5] text-[#13316B] font-semibold text-base rounded-2xl"
                  />
                  <div className="absolute top-1/2 -translate-y-1/2 left-4">
                    <PassIcon />
                  </div>
                  <div className="absolute top-1/2 -translate-y-1/2 right-4 cursor-pointer" title={ visibility ? 'Click để ẩn' : 'Click để xem' } onClick={() => setVisibility(prev => !prev)}>
                    { visibility ? <VisibilityOffIcon color="disabled" /> : <VisibilityIcon color="disabled" /> }
                  </div>
                </div>

                <FormControlLabel
                  control={<Checkbox value="remember" color="primary" />}
                  label="Remember me"
                  className="text-[#9CA7B6] my-2"
                />
                <ButtonCustom classes="w-full h-[55px]">Sign in</ButtonCustom>
              </Box>
            </div>
          </div>
        </div>
        <div className="h-full w-[55%] hidden md:block">
          <img src="/assets/imgLogin.png" alt="" className="h-full w-full" />
        </div>
      </div>
    </div>
  );
};

export default Login;
