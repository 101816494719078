import { FormHelperText } from "@mui/material";
import AuthServices from "api/auth";
import uploadService from "api/upload";
import { useDispatch } from "react-redux";
import { open } from "store/notificationSlice";

const UploadImage = ({
  value,
  onChange,
  imageAspect = "3/2",
  disabled = false,
  error,
  helperText,
  classes,
}) => {
  const dispatch = useDispatch();
  const handleImageChange = async (e) => {
    const file = e.target.files?.length && e.target.files[0];
    if (file && /^image\//.test(file.type)) {
      const formData = new FormData();
      formData.append("files", file);

      const { data } = await uploadService.uploadFile(formData);
      if (data[0]?.location) {
        onChange(data[0]?.location);
        await AuthServices.updateMe({
          avatar: data[0]?.location,
        });
        dispatch(
          open({
            isNoti: true,
            message: "Update Avatar successful",
          })
        );
      }
    }
  };

  return (
    <>
      <div className="w-full h-full">
        <input
          disabled={disabled}
          type="file"
          id="image"
          name="image"
          accept="image/*"
          onChange={handleImageChange}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            opacity: 0,
            cursor: "pointer",
          }}
        />
      </div>
      <FormHelperText error={error} className={classes}>
        {helperText}
      </FormHelperText>
    </>
  );
};

export default UploadImage;
