import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isNoti: false,
  message: "",
};

export const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    open: (state, actions) => {
      state.isNoti = actions.payload.isNoti;
      state.message = actions.payload.message;
    },
    close: (state) => {
      state.isNoti = false;
      state.message = "";
    },
  },
});

export const { open, close } = notificationSlice.actions;
export const getNotification = (state) => state.notification;
export default notificationSlice.reducer;
