import { FormHelperText } from "@mui/material";
import UploadServices from "api/upload";

const UploadFile = ({
  onChange,
  folder,
  disabled = false,
  error,
  helperText,
  classes,
}) => {
  const handleImageChange = async (e) => {
    const files = [];
    const file = e.target.files?.length && e.target.files;
    for (let index = 0; index < file.length; index++) {
      const element = file[index];
      files.push(element);
    }
    if (files.length) {
      const formData = new FormData();
      for (let index = 0; index < files.length; index++) {
        formData.append("files", files[index]);
      }
      const { data } = await UploadServices.uploadFile(formData);
      if (data) {
        onChange(data?.map((item) => item.location));
      }
    }
  };

  return (
    <>
      <div className="w-full h-full">
        <input
          multiple
          disabled={disabled}
          type="file"
          accept="*"
          onChange={handleImageChange}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            opacity: 0,
            cursor: "pointer",
          }}
        />
      </div>
      <FormHelperText error={error} className={classes}>
        {helperText}
      </FormHelperText>
    </>
  );
};

export default UploadFile;
