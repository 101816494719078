import { Box, Divider, Grid, Stack, TextField, Typography } from "@mui/material";
import ButtonCustom from "components/button";

const Create = ({ form, handleSubmitForm, handleChangeInput, handleClose }) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: 4,
    p: 4,
  };

  return (
    <Box p={3} sx={style} className="boxShadow w-[96%] md:w-[450px]">
      <Typography variant="h5" mb={3} fontWeight={700}>
        Thêm khách hàng
      </Typography>
      <Divider />
        <Grid container spacing={{ xs: 2, md: 2 }}>
          <Grid item xs={12}>
            <TextField
              id="outlined-basic"
              label="Tên khách hàng"
              variant="outlined"
              name="name"
              className="w-full"
              value={form.name}
              onChange={handleChangeInput}
            />
          </Grid>

        <Grid item xs={12}>
          <TextField
            label="Mã khách hàng"
            placeholder="Nhập hoặc hệ thống sẽ tự động tạo"
            variant="outlined"
            name="code"
            className="w-full"
            value={form.code}
            onChange={handleChangeInput}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="Số điện thoại"
            variant="outlined"
            name="phone"
            className="w-full"
            value={form.phone}
            onChange={handleChangeInput}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="Địa chỉ"
            variant="outlined"
            name="address"
            className="w-full"
            value={form.address}
            onChange={handleChangeInput}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            className="w-full h-[60px]"
            id="standard-basic"
            label="Lưu ý khách hàng"
            value={form?.note ?? ''}
            name="note"
            onChange={handleChangeInput}
            multiline
            inputProps={{
              style: {
                height: "80px",
              },
            }}
          />
        </Grid>
      </Grid>

      <Grid container className="w-full flex gap-2 justify-between mt-20">
        <ButtonCustom
          classes="w-[48%] py-3"
          variant="none"
          onClick={handleClose}
        >
          Huỷ
        </ButtonCustom>
        <ButtonCustom
          classes="w-[48%] py-3"
          variant="primary"
          onClick={handleSubmitForm}
        >
          Thêm
        </ButtonCustom>
      </Grid>
    </Box>
  );
};

export default Create;
